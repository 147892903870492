import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewMilestone = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [viewMilestoneName, setViewMilestoneName] = useState({});
    const [newMilestone, setNewMilestone] = useState({
        description: "", agegroup: ""
    });

    const handleInputs = (e) => {
        setNewMilestone({ ...newMilestone, [e.target.name]: e.target.value })
    }

    // post new api call
    const POST_URL = `/v1/add-milestone`;
    const postMilestone = async () => {
        try {
            const response = await api.post(POST_URL, {
                description: newMilestone.description,
                milestone_group_id: id,
                age_group: newMilestone.agegroup
            })
            if (response.status === 200) {
                navigate('/dashboard/milestonegroup', { replace: true })
                setNewMilestone({ description: "", agegroup: "" })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postMilestone();
    }

    // for name display
    useEffect(() => {
        loadMilestoneGP();
    }, []);

    const loadMilestoneGP = async () => {
        const URL = `/v1/milestoneGroups/${id}`
        const result = await api.get(URL);
        setViewMilestoneName(result.data);
    }

    const inputMissing = () => !(newMilestone.description && newMilestone.agegroup);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Milestone : ( {viewMilestoneName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Age Group"
                            name="agegroup"
                            id="agegroup"
                            onChange={handleInputs}
                            value={newMilestone.agegroup || ""}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={newMilestone.description || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewMilestone;