import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../Firebase';

export default function PasswordForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleClick = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                navigate("/login")
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    return (
        <>
            <Stack spacing={3}>
                <TextField name="email" type='email' label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ my: 2}}>
                Reset with Email
            </LoadingButton>
        </>
    );
}
