import React, { useRef, useEffect, useState } from 'react';
import './Media.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Stack, Typography, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../../Interceptors/Interceptor';

const EditSubMedia = ({ id, state, mediaList }) => {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const [files, setfiles] = useState([]);
  const [fileError, setFileError] = useState('');
  const [media, setMedia] = useState([]);
  const [subMediaId, setSubMediaId] = useState(null);

  const onFileDrop = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const updatedFiles = selectedFiles.filter((file) => {
      const fileSizeInMB = file.size / (1024 * 1024);
      const isVideo = file.type.includes('video');
      if (isVideo && fileSizeInMB > 2) {
        setFileError('File size exceeds the limit: 2MB');
        return false;
      }
      return true;
    });
    setfiles([...files, ...selectedFiles]);
  };

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const POST_IMAGE = `/v1/upload-image`;
  const putSubResourceMedia = async () => {
    try {
      const formData = new FormData();
      files.forEach((image) => {
        formData.append('gallery', image);
      });
      formData.append('path', 'resources-sub-medias');

      const response1 = await api.post(POST_IMAGE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      );
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const PUT_URL = `/v1/resources-sub-medias/${subMediaId}`;
        const response2 = await api.put(
          PUT_URL,
          {
            media_link: response1.data.gallery[0],
            resources_media_id: id,
          }
        );
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
      navigate('/dashboard/resourcesmedia', {
        replace: true,
        state: {
          stage: state.state.stage,
          featured: state.state.featured,
        },
      });
      setfiles([]);
    } catch (err) {
      // console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    putSubResourceMedia();
  };

  const fileRemove = (name) => {
    const newfiles = files.filter((list) => list.name !== name);
    setfiles(newfiles);

    // Clear fileError if it exists for the removed file
    const removedFile = files.find((file) => file.name === name);
    if (removedFile && removedFile.type.includes('video') && fileError) {
      setFileError('');
    }
  };


  useEffect(() => {
    setMedia(mediaList);

    if (mediaList && mediaList.length > 0) {
      setSubMediaId(mediaList[0].id);
    }
  }, [mediaList]);


  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={3} mt={5}>
        <Typography variant="h4" gutterBottom>
          Edit Sub Resource Media
        </Typography>
      </Stack>
      {
        media.length > 0 && (
          media.map((m, index) => (
            <div
              key={`thumb-${index}`}
              style={{ marginTop: '16px' }}>
              {
                m.media_link.includes(".mp4") ? (
                  <video controls style={{ maxWidth: '100%', maxHeight: '400px' }}>
                    <track default kind="captions" src="" />
                    <source src={m.media_link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) :
                  (
                    <img
                      src={m.media_link}
                      alt="Selected"
                      style={{ width: '600px', objectFit: 'cover' }}
                    />
                  )
              }
            </div>
          ))
        )
      }
      <Box>
        <div
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="drop-file-input__label">
            <p>Drag & Drop your file in .png, .jpg, GIF, video to update the content of the media</p>
          </div>
          <input id="fileInput" type="file" accept=".png, .jpg, .gif, .jpeg, .mp4" onChange={onFileDrop} />
        </div>
        {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
        {files.length > 0 ? (
          <div className="drop-file-preview">
            {
              files.map((item, index) => (
                <div key={`preview-${index}`} className="drop-file-preview__item">
                  <div className="drop-file-preview_item_info">
                    <p>{item.name}</p>
                  </div>
                  <CancelIcon color="error" onClick={() => fileRemove(item.name)} />
                </div>
              ))
            }
          </div>
        ) : null}
        <Button variant="contained" style={{ marginTop: '1rem' }} disabled={!files[0]} onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Container >
  );
};

export default EditSubMedia;
