import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Card, Table, Stack, Button, TableRow,
    TableBody, TableCell, Container, Typography,
    TableContainer, TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import QueAnsReadonly from './QueAnsReadonly';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
    { id: 'id', label: 'Number', alignRight: false },
    { id: 'question', label: 'Questions', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
];

export default function ViewQuizTopic() {
    const { id } = useParams()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [viewQuizTopic, setViewQuizTopic] = useState([]);
    const [idToDelete, setIdToDelete] = useState({});
    const [viewQuesions, setViewQuesions] = useState({});
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const [viewQusOptions, setViewQusOptions] = useState({});

    const toggle = () => setOpen(!open);
    const toggle1 = () => setClose(!close);

    // get api call
    useEffect(() => {
        getviewQuizTopic();
        loadQuiz();
    }, []);

    const getviewQuizTopic = async () => {
        const GET_URL = `/v1/quiz-questions-of-category/${id}`
        try {
            const response = await api.get(GET_URL)
            const sortedResponse = response.data.sort((a, b) => {
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);
                return dateB - dateA;
            });
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
            setViewQuizTopic(sortedResponse)
        } catch (err) {
            // console.log(err)
        }
    };

    // readonly que-ans apis
    const getQuestion = async (id) => {
        const GET_URL = `/v1/quiz-questions/${id}`
        try {
            const response = await api.get(GET_URL)
            setViewQusOptions(response.data)
        } catch (err) {
            // console.log(err)
        }
    };

    // delete api call
    const handleDelete = async () => {
        const DELETE_URL = `/v1/quiz-questions/${idToDelete.id}`
        try {
            const response = await api.delete(DELETE_URL)
            getviewQuizTopic();
            toggle();
            const newRowCount = viewQuizTopic.length - 1;
            const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            if (page >= newPageCount) {
                setPage(newPageCount - 1);
            }
        } catch (err) {
            toggle()
            // console.log(err)
        }
    }

    const loadQuiz = async () => {
        const URL = `/v1/quiz-categories/${id}`
        const result = await api.get(URL);
        setViewQuesions(result.data);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(viewQuizTopic.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - viewQuizTopic.length) : 0;

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Quiz-Category :  {viewQuesions.name}
                    </Typography>
                    <Link to={`/dashboard/newquiztopic/${id}`}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Quiz Question
                        </Button>
                    </Link>
                </Stack>

                <Card>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={viewQuizTopic.length}
                                />
                                <TableBody>
                                    {viewQuizTopic.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currViewQuiz, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell align="left">{(index + 1 + rowsPerPage * page)}</TableCell>
                                                <TableCell align="left">{currViewQuiz.question}</TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { getQuestion(currViewQuiz.id); toggle1(); }} >View Answers</Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/dashboard/editquiztopic/${id}/${currViewQuiz.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currViewQuiz); toggle(); }}
                                                    >Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={viewQuizTopic && viewQuizTopic.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal
                isOpen={open} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggle}>Question : {idToDelete.question} </ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggle}> No</Button>
                    <Button color="primary" onClick={handleDelete}> Yes </Button>
                </ModalFooter>
            </Modal >

            {/* modal for view  question */}
            <Modal isOpen={close} size='lg' style={{ marginTop: '13rem', maxWidth: '800px', width: '100%' }}>
                <ModalHeader toggle={toggle1}>Question: {viewQusOptions.question}</ModalHeader>
                <QueAnsReadonly viewQusOptions={viewQusOptions} />
                <ModalFooter>
                    <Button color="primary" onClick={toggle1}>Close</Button>
                </ModalFooter>
            </Modal >

        </>
    );
}
