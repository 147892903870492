import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Card, Table, Stack, Button, TableRow, TableBody, TableCell, Container, TableContainer,
    TablePagination, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
];

export default function Vaccination() {
    const state = useLocation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [idToDelete, setIdToDelete] = useState({});
    const [dataOptions, setDataOptions] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [countryName, setCountryName] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState(state.state != null ? state.state.country : "");

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    useEffect(() => {
        getcountryIdBy();
    }, []);

    // get api call country name
    const getcountryIdBy = async () => {
        const GET_URL = `/v1/get-all-countries`
        try {
            const response = await api.get(GET_URL)
            setDataOptions(response.data);
        } catch (err) {
            // console.log(err)
        }
    };

    // get  data country wise show vaccine detail
    useEffect(() => {
        loadVaccination()
    }, [selectedCountryId || 3]);

    const loadVaccination = async () => {
        const GET_URL = `/v1/get-vaccination-time-in-Country/${selectedCountryId || 3}`
        try {
            const response = await api.get(GET_URL
            );
            getcountryIdBy()
            const sortedResponse = response.data.sort((a, b) => {
                const dateA = new Date(a.id);
                const dateB = new Date(b.id);
                return dateA - dateB;
            });
            setCountryData(sortedResponse);
        } catch (err) {
            // console.log(err)
        }
    }

    // delete api call
    const handleDelete = async () => {
        const DELETE_URL = `/v1/vaccinationTime/${idToDelete.id}`
        try {
            const response = await api.delete(DELETE_URL)
            loadVaccination();
            toggle();
            const newRowCount = countryData.length - 1;
            const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            if (page >= newPageCount) {
                setPage(newPageCount - 1);
            }
        } catch (err) {
            toggle();
            // console.log(err)
        }
    }

    // get api call country name
    useEffect(() => {
        if (selectedCountryId || 3) {
            getcountryName();
        }
    }, [selectedCountryId || 3]);

    const getcountryName = async () => {
        const GET_URL = `/v1/countries/${selectedCountryId || 3}`
        try {
            const response = await api.get(GET_URL);
            setCountryName(response.data);
        } catch (err) {
            // console.log(err)
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(countryData.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - countryData.length) : 0;

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel>Country ID</InputLabel>
                            <Select
                                id="countryid"
                                label="Country ID"
                                // name='countryid'
                                sx={{ p: 1 }}
                                value={selectedCountryId || 3}
                                onChange={event => {
                                    setSelectedCountryId(Number(event.target.value));
                                }}
                            >
                                <MenuItem value="3" />
                                {dataOptions.map((countryName, index) => (
                                    <MenuItem key={index} value={countryName.id}>{countryName.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <Link state={{ country: selectedCountryId }}
                        to={`/dashboard/newvaccination/${selectedCountryId || 3}`}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Vaccination Times
                        </Button>
                    </Link>

                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={countryData.length}
                                />
                                <TableBody>
                                    {countryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currCountryData, index) => (
                                            <TableRow hover key={index} >
                                                <TableCell align="left" >{(index + 1 + rowsPerPage * page)}</TableCell>
                                                <TableCell align="left">{currCountryData.name}</TableCell>
                                                <TableCell align="left">{currCountryData.description}</TableCell>
                                                <TableCell>
                                                    <Link to={`/dashboard/viewvaccine/${selectedCountryId || 3}/${currCountryData.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }}> View Vaccine</Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell> <Link state={{ country: selectedCountryId }} to={`/dashboard/editvaccination/${selectedCountryId || 3}/${currCountryData.id}`}>
                                                    <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                                                </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currCountryData); toggle(); }}
                                                    >Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={countryData && countryData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal isOpen={open} style={{ marginTop: '13rem', }}>
                <ModalHeader toggle={toggle}>Topic : {idToDelete.name}</ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>No</Button>
                    <Button color="primary" onClick={handleDelete}>Yes </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
