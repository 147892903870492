import { Button, Container, TextField, Box, Typography, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditCommunityTopic = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [communityCategoryName, setCommunityCategoryName] = useState([]);
    const [updatedTopic, setUpdatedTopic] = useState({
        name: "", created_by: "", community_category_id: ""
    });

    const handleInputs = (e) => {
        setUpdatedTopic({ ...updatedTopic, [e.target.name]: e.target.value })
    }

    // update category api call
    const PUT_URL = `/v1/community-topics/${id}`;
    const putCommunityTopic = async () => {
        try {
            const response = await api.put(PUT_URL, {
                name: updatedTopic.name,
                created_by: updatedTopic.created_by,
                community_category_id: updatedTopic.community_category_id,
            })
            if (response.status === 200) {
                navigate('/dashboard/communitytopic', { replace: true })
            } else {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putCommunityTopic();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadCommunityTopic();
        getCommunityCategory();
    }, []);

    const loadCommunityTopic = async () => {
        const URL = `/v1/community-topics/${id}`
        const result = await api.get(URL);
        setUpdatedTopic(result.data)
    }

    // get api call
    const getCommunityCategory = async () => {
        const GET_URL = `/v1/community-categories`
        try {
            const response = await api.get(GET_URL)
            setCommunityCategoryName(response.data)
        } catch (err) {
            // console.log(err)
        }
    };
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Community Topic
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div >
                        <TextField
                            label="Topic"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedTopic.name || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Created By"
                            name="created_by"
                            id="created_by"
                            onChange={handleInputs}
                            value={updatedTopic.created_by || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel>Community Category</InputLabel>
                            <Select
                                id="community_category_id"
                                label="Community Category"
                                name='community_category_id'
                                sx={{ p: 1 }}
                                value={communityCategoryName.some(resource => resource.id === updatedTopic.community_category_id) ? updatedTopic.community_category_id : ''}
                                onChange={handleInputs}
                            >
                                {communityCategoryName.map((foodName, index) => (
                                    <MenuItem key={index} value={foodName.id}>{foodName.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={!updatedTopic.name}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditCommunityTopic;