import React from 'react';

import CountryPage from './CountryPage';
import PhoneSelect from './PhoneSelect';
import LanguagePage from './LanguagePage';
import AcquisitionPage from './AcquisitionPage';
import DateRangeCalendar from './DateRangeCalendar';

const AppDropDown = () => {

  return (
    <>
      <DateRangeCalendar />
      <CountryPage />
      <PhoneSelect />
      <LanguagePage />
      <AcquisitionPage />
    </>
  );
};

export default AppDropDown;
