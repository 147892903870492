import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material'; 
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Iconify from '../../components/iconify/Iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import api from '../../Interceptors/Interceptor';
import { fDateTime } from '../../utils/formatTime';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'description', label: 'Message', alignRight: false },
    { id: 'stage', label: 'Stage', alignRight: false },
    { id: 'platform', label: 'Platform', alignRight: false },
    { id: 'scheduledtime', label: 'Scheduled Time', alignRight: false },
    { id: 'senttime', label: 'Sent Time', alignRight: false },
    { id: '1' },

];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const Notification = () => {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [notification, setNotification] = useState([]);
    const [comments, setComments] = useState([]);
    const [dataById, setDataById] = useState({});
    const [open, setOpen] = useState(false);
    const [communityNamebyId, setCommunityNamebyId] = useState([]);
    const [selectedStageId, setSelectedStageId] = useState('');
    const toggle = () => setOpen(!open);

    const getNotification = async () => {
    const GET_URL = `/v1/get-all-fcm-notifications`;
        try {
      const response = await api.get(GET_URL);
      setNotification(response.data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // console.log(err)
    }
  };
  const mapStageToLabel = (stageId) => {
    switch (stageId) {
      case 1:
        return 'Pre-pregnancy';
      case 2:
        return 'Pregnancy';
      case 3:
        return 'Parenting';
      default:
        return 'Unknown';
        }
    };

    useEffect(() => {
        getNotification(); 
      }, []);

    const handleDelete = async () => {
        if (comments.length === 0) {
            const token = localStorage.getItem('token').replace(/"/g, '');
            const DELETE_URL = `/v1/fcm-notifications/${dataById.id}`;
            try {
                const response = await api.delete(DELETE_URL, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                getNotification();
                toggle();
                const newRowCount = notification.length - 1;
                const newPageCount = Math.ceil(newRowCount / rowsPerPage);
                if (page >= newPageCount) {
                    setPage(newPageCount - 1);
                }
            } catch (err) {
                toggle();
                //   console.log(err);
            }
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(notification.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notification.length) : 0;

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Notifications
                    </Typography>

                    {/* <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                        <InputLabel id="demo-select-small">Stage</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            label="Stage"
                            sx={{ p: 1 }}
                            value={selectedStageId || ''}
                            onChange={event => {
                                setSelectedStageId(Number(event.target.value));
                            }}>
                            <MenuItem value={1}>Pre-pregnancy</MenuItem>
                            <MenuItem value={2}>Pregnancy</MenuItem>
                            <MenuItem value={3}>Parenting</MenuItem>
                        </Select>
                    </FormControl> */}

                    <Link to={`/dashboard/newNotification`}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            Notification
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={notification.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {applySortFilter(notification, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currNotification, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell align="left">{index + 1 + rowsPerPage * page}</TableCell>
                                                <TableCell align="left">{currNotification.title}</TableCell>
                                                <TableCell align="left">{currNotification.message}</TableCell>
                        <TableCell align="left">{mapStageToLabel(currNotification.stage)}</TableCell>
                                                <TableCell align="left">{currNotification.platform}</TableCell>
                                                <TableCell align="left">{fDateTime(currNotification.scheduled_date_time) }</TableCell> 
                                                <TableCell align="left">{fDateTime(currNotification.sent_date_time) }</TableCell> 
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 1 }}
                                                        onClick={() => {
                                                            setDataById(currNotification); 
                              console.log(dataById);
                                                            toggle();
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={notification && notification.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

      <Modal isOpen={open} style={{ marginTop: '13rem' }}>
        <ModalHeader toggle={toggle}>Notification : {dataById.title}</ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            No
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Yes{' '}
          </Button>
        </ModalFooter>
      </Modal>
        </>
    );
};
export default Notification;
