import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const EditQuizCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const state = useLocation();
    const [editQuizCategory, setEditQuizCategory] = useState({
        file: "", name: "", stage: ""
    });

    const handleInputs = (e) => {
        if (e.target.name === "file") {
            setEditQuizCategory({ ...editQuizCategory, [e.target.name]: e.target.files[0] });
        } else {
            setEditQuizCategory({ ...editQuizCategory, [e.target.name]: e.target.value });
        }
    }
    // put Quiz api call
    const formData = new FormData();
    formData.append('image', editQuizCategory.file);
    formData.append('path', 'quiz-categories');

    const PUT_IMAGE = `/v1/upload-image`;
    const PUT_URL = `/v1/quiz-categories/${id}`;
    const putQuizCategory = async () => {
        try {
            const response1 = await api.post(PUT_IMAGE, formData);
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.put(PUT_URL,
                    {
                        image_url: response1.data.image_url,
                        name: editQuizCategory.name,
                        stage: editQuizCategory.stage,
                    })
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/quiz', {
                replace: true,
                state: {
                    stage: state.state.stage,
                },
            })
        } catch (err) {
            //  console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putQuizCategory();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadQuizCategory();
    }, []);

    const loadQuizCategory = async () => {
        const URL = `/v1/quiz-categories/${id}`
        const result = await api.get(URL);
        setEditQuizCategory(result.data);
    }

    const inputMissing = () => !(editQuizCategory.name && editQuizCategory.stage);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Quiz Category
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        component="img"
                        sx={{ height: 150, width: 200, borderRadius: '0', }} alt=""
                        src={editQuizCategory.image_url}
                    />
                    <div>
                        <TextField
                            // label="Thumbnail"
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleInputs} />
                            
                    </div>
                    <div >
                        <TextField
                            label="Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={editQuizCategory.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="demo-select-small">Stage</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                label="Stage"
                                name='stage'
                                sx={{ p: 1 }}
                                value={editQuizCategory.stage || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                                <MenuItem value={2}>Pregnancy</MenuItem>
                                <MenuItem value={3}>Parenting</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditQuizCategory;