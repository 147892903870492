import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewConstantsTypes = () => {
    const navigate = useNavigate();
    const [newConstantsTypes, setNewConstantsTypes] = useState({
        name: ""
    });

    const handleInputs = (e) => {
        setNewConstantsTypes({ ...newConstantsTypes, [e.target.name]: e.target.value })
    }

    // add new api
    const POST_URL = `/v1/add-constantType`;
    const postnewconstanttypes = async () => {
        try {
            const response = await api.post(POST_URL,
                {
                    name: newConstantsTypes.name,
                })
            if (response.status === 200) {
                navigate('/dashboard/constanttypes', { replace: true })
                setNewConstantsTypes({ name: "" })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postnewconstanttypes();
    }

    const inputMissing = () => !(newConstantsTypes.name);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Constants Types
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div >
                        <TextField
                            label="New Constants Types"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newConstantsTypes.name || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewConstantsTypes;