import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import api from '../../Interceptors/Interceptor';

const NewCountries = () => {
    const navigate = useNavigate();
    const [newCountries, setNewCountries] = useState({
        countries: "", code: ""
    });

    const handleInputs = (e) => {
        setNewCountries({ ...newCountries, [e.target.name]: e.target.value })
    }

    // add new country
    const POST_URL = `/v1/add-country`;
    const fetchData = async () => {
        try {
            const response = await api.post(POST_URL,
                {
                    name: newCountries.countries,
                    code: newCountries.code
                })
            if (response.status === 200) {
                navigate('/dashboard/countries', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchData();
        setNewCountries({ countries: "", code: "" })
    }

    const inputMissing = () => !(newCountries.countries && newCountries.code)
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Country
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div >
                        <TextField
                            label="Countries"
                            name="countries"
                            id="countries"
                            onChange={handleInputs}
                            value={newCountries.countries || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Code"
                            name="code"
                            id="code"
                            onChange={handleInputs}
                            value={newCountries.code || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewCountries;