import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, Table, Stack, Avatar, Button, TableRow, TableBody,
    TableCell, Container, Typography, TableContainer, TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'stage', label: 'Stage', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function DeliveryChecklist() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [deliveryChecklist, setDeliveryChecklist] = useState([]);
    const [idToDelete, setIdToDelete] = useState({});
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);

    useEffect(() => {
        getDeliveryChecklist()
    }, []);

    // get api call
    const getDeliveryChecklist = async () => {
        const GET_URL = `/v1/get-all-deliveryChecklist-groups`
        try {
            const response = await api.get(GET_URL)
            setDeliveryChecklist(response.data)
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // console.log(err)
        }
    };

    // delete api call
    const handleDelete = async () => {
        const DELETE_URL = `/v1/deliveryChecklistGroups/${idToDelete.id}`
        try {
            const response = await api.delete(DELETE_URL)
            getDeliveryChecklist();
            toggle();
            const newRowCount = deliveryChecklist.length - 1;
            const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            if (page >= newPageCount) {
                setPage(newPageCount - 1);
            }
        } catch (err) {
            toggle();
            // console.log(err)
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(deliveryChecklist.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deliveryChecklist.length) : 0;

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Delivery Checklist Group
                    </Typography>
                    <Link to={`/dashboard/newdeliverychecklist`}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Delivery Checklist
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={deliveryChecklist.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {applySortFilter(deliveryChecklist, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currDeliveryChecklist, index) => (
                                            <TableRow hover key={index} >
                                                <TableCell align="left" >{(index + 1 + rowsPerPage * page)}</TableCell>
                                                <TableCell align="left">{currDeliveryChecklist.name}</TableCell>
                                                <TableCell align="left">{currDeliveryChecklist.stage === 1 ? "Pre-pregnancy" : currDeliveryChecklist.stage === 2 ? "Pregnancy" : "Parenting"}</TableCell>
                                                <TableCell>
                                                    <Link to={`/dashboard/viewdeliveryvalue/${currDeliveryChecklist.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }}  >View Value</Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/dashboard/editdeliverychecklist/${currDeliveryChecklist.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currDeliveryChecklist); toggle(); }}
                                                    >Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={deliveryChecklist && deliveryChecklist.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal isOpen={open} style={{ marginTop: '13rem', }}>
                <ModalHeader toggle={toggle}>Delivery Checklist : {idToDelete.name}</ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>No</Button>
                    <Button color="primary" onClick={handleDelete}>Yes </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
