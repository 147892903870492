import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../Firebase'
import Iconify from '../../../components/iconify';
import api from '../../../Interceptors/Interceptor';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        getUserByUID(user.uid)
      })
      .catch((error) => {
        setError(true)
        console.log(error.message);
      });
  };

  // get userby firebase id api call 
  const getUserByUID = async (UID) => {
    const GET_URL = `/v1/usersByFirebaseId/${UID}`
    try {
      const response = await api.get(GET_URL)
      if (response.status === 200) {
        navigate('/dashboard', { replace: true })
        localStorage.setItem("token", JSON.stringify(response.data.token))
        localStorage.setItem("userDetail", JSON.stringify(response.data))
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        navigate('/login', { replace: true });
      } else {
        console.log('Unhandled error',err);
      }
    }
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    });
    return (() => {
      unsubscribe();
    })
  }, [])

  const handlePassword = () => {
    navigate('/password', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField name="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: 'pointer' }}>
        <Link variant="subtitle2" underline="hover" onClick={handlePassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
      {error && <Typography variant="body2" sx={{ mt: 1, textAlign: 'center', color: 'red' }}>
        Wrong Email or Password!
      </Typography>}
    </>
  );
}