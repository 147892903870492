import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditMilestone = () => {
    const { categoryId, id } = useParams();
    const navigate = useNavigate();
    const [viewMilestoneName, setViewMilestoneName] = useState({});
    const [editMilestone, setEditMilestone] = useState({
        description: "", age_group: ""
    });

    const handleInputs = (e) => {
        setEditMilestone({ ...editMilestone, [e.target.name]: e.target.value })
    }

    // put new api call
    const PUT_URL = `/v1/milestones/${id}`;
    const putMilestone = async () => {
        try {
            const response = await api.put(PUT_URL, {
                description: editMilestone.description,
                milestone_group_id: categoryId,
                age_group: editMilestone.age_group
            })
            if (response.status === 200) {
                navigate('/dashboard/milestonegroup', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putMilestone();
    };

    // get priveous data on edit page
    useEffect(() => {
        loadMilestone();
        loadMilestoneName();
    }, []);
    const loadMilestone = async () => {
        const URL = `/v1/milestones/${id}`
        const result = await api.get(URL);
        setEditMilestone(result.data);
    }

    // for name display
    const loadMilestoneName = async () => {
        const URL = `/v1/milestoneGroups/${categoryId}`
        const result = await api.get(URL);
        setViewMilestoneName(result.data);
    }

    const inputMissing = () => !(editMilestone.description && editMilestone.age_group);
    return (
        <>
            <Container sx={{ display: 'table-caption' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Milestone : ( {viewMilestoneName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Age Group"
                            name="age_group"
                            id="age_group"
                            onChange={handleInputs}
                            value={editMilestone.age_group || ""}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={editMilestone.description || ""}
                        />
                    </div>
                    <Button variant="contained" style={{ marginLeft: '10px' }} disabled={inputMissing()} onClick={handleSubmit}>
                        Update
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default EditMilestone;
