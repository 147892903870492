import { Button, Container, Box, Typography, Stack } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditConstantEditor = () => {
    const { categoryId, id } = useParams()
    const editor = useRef(null);
    const navigate = useNavigate();
    const [viewConstTypeName, setViewConstTypeName] = useState({});
    const [editConstantEditor, setEditConstantEditor] = useState({ value: "" });

    const contentFieldChanged = (data) => {
        setEditConstantEditor({ ...editConstantEditor, 'value': data })
    }

    // put new api call
    const PUT_URL = `/v1/constants/${id}`;
    const putConstantValue = async () => {
        try {
            const response = await api.put(PUT_URL, {
                value: editConstantEditor.value,
                type: categoryId,
            })
            if (response.status === 200) {
                navigate('/dashboard/constanttypes', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putConstantValue();
    };

    // get priveous data on edit page
    useEffect(() => {
        loadConstanttypes();
        loadConstantType();
    }, []);
    const loadConstanttypes = async () => {
        const URL = `/v1/constants/${id}`
        const result = await api.get(URL);
        setEditConstantEditor(result.data);
    }

    // for name display
    const loadConstantType = async () => {
        const URL = `/v1/constantTypes/${categoryId}`
        const result = await api.get(URL)
        setViewConstTypeName(result.data);
    }

    const inputMissing = () => !(editConstantEditor.value);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Constant Value : ( {viewConstTypeName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '48ch' }
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{ maxWidth: '80%' }}>
                        <JoditEditor
                            ref={editor}
                            value={editConstantEditor.value || ""}
                            onChange={(newContent) => contentFieldChanged(newContent)}
                        />
                    </div>
                    <Button variant='contained'
                        disabled={inputMissing()}
                        onClick={handleSubmit}>Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditConstantEditor;