import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import api from '../../../Interceptors/Interceptor';

const NewVaccination = () => {
    const state = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [countryName, setCountryName] = useState([]);
    const [newVaccination, setNewVaccination] = useState({
        name: "", description: ""
    });

    const handleInputs = (e) => {
        setNewVaccination({ ...newVaccination, [e.target.name]: e.target.value })
    }

    // post new api call
    const POST_URL = `/v1/add-vaccination-time`
    const postVaccination = async () => {
        try {
            const response = await api.post(POST_URL, {
                name: newVaccination.name,
                description: newVaccination.description,
                country_id: id,
            })
            if (response.status === 200) {
                navigate(`/dashboard/vaccination`, {
                    replace: true,
                    state: {
                        country: state.state.country,
                    },
                })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await postVaccination();
        setNewVaccination({ name: "", description: "" })
    }

    // get api call country name
    useEffect(() => {
        getcountryName();
    }, []);

    const getcountryName = async () => {
        const GET_URL = `/v1/countries/${id}`
        try {
            const response = await api.get(GET_URL)
            setCountryName(response.data)
        } catch (err) {
            // console.log(err)
        }
    };

    const inputMissing = () => !(newVaccination.name && newVaccination.description);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Vaccination : ( {countryName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newVaccination.name || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={newVaccination.description || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewVaccination;