import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card, CardHeader, CardMedia, CardContent, Button, } from '@mui/material';
import { Interweave } from 'interweave';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../../Interceptors/Interceptor';

export default function PreviewResourcesArticles() {
  const { id } = useParams();
  const [previewArticle, setPreviewArticle] = useState([]);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    getResourcesArticles();
  }, []);

  // get api call
  const getResourcesArticles = async () => {
    const GET_URL = `/v1/resource-articles/${id}`;
    try {
      const response = await api.get(GET_URL);
      setPreviewArticle(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <Container style={{ display: '-webkit-inline-box' }}>
      <Button variant='contained' onClick={goBack}><ArrowBackIcon /></Button>
      <Card sx={{ maxWidth: 384, minHeight: 800, marginLeft: 30 }}>
        <CardHeader title={previewArticle.name} />
        <CardMedia
          component="img"
          // height="250"
          image={previewArticle.image_url}
          alt={previewArticle.name}
          style={{ marginTop: '1rem' }}
        />
        <CardContent>
          <Interweave content={previewArticle.content} />
        </CardContent>
      </Card>
    </Container>
  );
}
