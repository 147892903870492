import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Table, Stack, Button,
  TableRow, TableBody, TableCell, Container,
  Typography, TableContainer, TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Topic Name', alignRight: false },
  // { id: 'createdby', label: 'Created_by', alignRight: false },
  { id: 'createId', label: 'Community Category', alignRight: false },
  { id: '1' },
  { id: '2' },
  { id: '3' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const CommunityTopic = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [communityTopic, setCommunityTopic] = useState([]);
  const [comments, setComments] = useState([]);
  const [dataById, setDataById] = useState({});
  const [open, setOpen] = useState(false);
  const [communityNamebyId, setCommunityNamebyId] = useState([]);

  const toggle = () => setOpen(!open);

  // get community topic api call
  useEffect(() => {
    getCommunityTopic();
    getCommunityCategory();
  }, []);

  const getCommunityTopic = async () => {
    const GET_URL = `/v1/community-topics`;
    try {
      const response = await api.get(GET_URL);
      setCommunityTopic(response.data);
      console.log('community-topics', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      // console.log(err)
    }
  };

  // get community category name
  const getCommunityCategory = async () => {
    const GET_URL = `/v1/community-categories`;
    try {
      const response = await api.get(GET_URL);
      setCommunityNamebyId(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      //   console.log(err);
    }
  };

  const getCategoryObjectById = (categoryId) => communityNamebyId.find((item) => item.id === categoryId);

  // comments apis

  useEffect(() => {
    getCommunityComments();
  }, [dataById]);

  const getCommunityComments = async () => {
    const GET_URL = `/v1/community-discussion-by-topic/${dataById.id}`;
    try {
      const response = await api.get(GET_URL);
      setComments(response.data);
    } catch (err) {
      // console.log(err)
    }
  };

  const handleDelete = async () => {
    if (comments.length === 0) {
      const DELETE_URL = `/v1/community-topics/${dataById.id}`;
      try {
        const response = await api.delete(DELETE_URL);
        getCommunityTopic();
        toggle();
        const newRowCount = communityTopic.length - 1;
        const newPageCount = Math.ceil(newRowCount / rowsPerPage);
        if (page >= newPageCount) {
          setPage(newPageCount - 1);
        }
      } catch (err) {
        toggle();
        //   console.log(err);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(communityTopic.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - communityTopic.length) : 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Community Topics
          </Typography>
          <Link to={`/dashboard/newcommunitytopic`}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Community Topic
            </Button>
          </Link>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={communityTopic.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {applySortFilter(communityTopic, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currCommunityTopic, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="left">{index + 1 + rowsPerPage * page}</TableCell>
                        <TableCell align="left">{currCommunityTopic.name}</TableCell>
                        {/* <TableCell align="left">{currCommunityTopic.created_by}</TableCell> */}
                        <TableCell align="left">
                          {getCategoryObjectById(currCommunityTopic.community_category_id)?.name}
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/commentspage/${currCommunityTopic.id}`}>
                            <Button
                              variant="contained"
                              sx={{ mt: 1 }}
                            >
                              View Comments
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/editcommunitytopic/${currCommunityTopic.id}`}>
                            <Button variant="contained" sx={{ mt: 1 }}>
                              Edit
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={() => {
                              setDataById(currCommunityTopic);
                              getCommunityComments();
                              toggle();
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={communityTopic && communityTopic.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* modal for delete item */}
      {comments.length !== 0 ? (
        <Modal isOpen={open} style={{ marginTop: '13rem' }}>
          <ModalHeader toggle={toggle}>Topic: {dataById.name}</ModalHeader>
          <ModalBody>First delete the comments before deleting the topic.</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal isOpen={open} style={{ marginTop: '13rem' }}>
          <ModalHeader toggle={toggle}>Topic: {dataById.name}</ModalHeader>
          <ModalBody>Sure, do you want to delete?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              No
            </Button>
            <Button color="primary" onClick={handleDelete}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
export default CommunityTopic;
