import { Button, Container, TextField, Box, Typography, FormControlLabel, Checkbox, Stack, FormGroup } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditSubMedia from './SubMedia/EditSubMedia';

import api from '../../../Interceptors/Interceptor';

const EditResourceMedia = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const navigate = useNavigate();
  const state = useLocation();

  const [file, setFile] = useState(null);
  const [imgThumbnail, setImgThumbnail] = useState(null);
  const [resourceSubMedia, setResourceSubMedia] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [isFeatured, setIsFeatured] = useState(0);
  const [resourceCategory, setResourceCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const user = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    // Fetch resource category from the API on component mount
    const fetchResourceCategories = async () => {
      const GET_URL = `/v1/resource-categories`;
      try {
        const response = await api.get(GET_URL);
        if (!response.status === 200) {
          const error = new Error(response.error);
          throw error;
        }
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching countries', error);
      }
    };

    // Fetch resource media from the API on component mount
    const fetchResourceMedia = async () => {
      const GET_URL = `/v1/resources-medias/${id}`;
      try {
        const response = await api.get(GET_URL);
        if (!response.status === 200) {
          const error = new Error(response.error);
          throw error;
        }
        setImgThumbnail(response.data.media_link);
        setIsFeatured(response.data.featured ? 1 : 0);
        setIsActive(response.data.active ? 1 : 0);
        setResourceCategory(response.data.resources_category_id);
        setSelectedCountries(response.data.countries);
        setSelectedLanguage(response.data.language);
        setResourceSubMedia(response.data.ResourceSubMedia);
      } catch (error) {
        console.error('Error fetching countries', error);
      }
    };

    // Fetch countries list from the API on component mount
    const fetchCountries = async () => {
      const GET_URL = `/v1/get-all-countries`;
      try {
        const response = await api.get(GET_URL);
        if (!response.status === 200) {
          const error = new Error(response.error);
          throw error;
        }
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries', error);
      }
    };

    // Fetch languages list from the API on component mount
    const fetchLanguages = async () => {
      const GET_URL = `/v1/get-all-languages`;
      try {
        const response = await api.get(GET_URL);
        if (!response.status === 200) {
          const error = new Error(response.error);
          throw error;
        }
        setLanguages(response.data);
      } catch (error) {
        console.error('Error fetching languages', error);
      }
    };

    fetchCountries().then((val) => {
      fetchLanguages().then((valLang) => {
        fetchResourceCategories().then((res) => {
          fetchResourceMedia();
        });
      });
    });

  }, []);


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'image/png') {
      setFile(selectedFile);
    } else {
      alert('Please select a PNG image.');
    }
  };

  const handleFeaturedChange = (e) => {
    const { checked } = e.target; 
    setIsFeatured(checked ? 1 : 0); 
  };

  const handleActiveChange = (e) => {
    const { checked } = e.target;
    setIsActive(checked ? 1 : 0); 
  };

  const handleCategoryChange = (e) => {
    setResourceCategory(e.target.value);
  };

  const handleCountryChange = (e) => {
    const { value, checked } = e.target;

    const parsedValue = parseInt(value, 10);
    if (checked) {
      const prevCountries = [...selectedCountries];
      setSelectedCountries([...prevCountries, parsedValue]);
    } else {
      setSelectedCountries((prev) => prev.filter(country => country !== parsedValue))
    }
    console.log(selectedCountries)
  };

  const handleLanguageChange = (e) => {
    if (e.target.value) {
      setSelectedLanguage(parseInt(e.target.value, 10));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!resourceCategory || selectedCountries.length === 0 || !selectedLanguage) {
      alert('Please fill all fields.');
      return;
    }

    const formData = new FormData();
    if (file) formData.append('image', file);
    formData.append('path', 'resources-medias');

    try {
      const payload = {
        created_by: user.name,
        resources_category_id: resourceCategory,
        active: isActive,
        featured: isFeatured
      };

      if (file) {
        const resImg = await api.post(`/v1/upload-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (resImg.status !== 200) {
          throw new Error("Failed to save image");
        }
        payload.media_link = resImg.data.image_url;
      }

      const resMedia = await api.put(`/v1/resources-medias/${id}`, payload);
      if (resMedia.status !== 200) {
        throw new Error("Failed to save data");
      }

      const resMediaCountries = await api.post(`/v1/resource-medias-countries`,
        {
          mediaId: id,
          countries: JSON.stringify(selectedCountries)
        });
      if (resMediaCountries.status !== 200) {
        throw new Error("Failed to save countires");
      }

      const resMediaLanguage = await api.post(`/v1/resource-medias-language`,
        {
          mediaId: id,
          language: selectedLanguage
        });
      if (resMediaLanguage.status !== 200) {
        throw new Error("Failed to save language");
      }

      alert('Resource media updated successfully!');

      navigate('/dashboard/resourcesmedia', {
        replace: true
      });
    } catch (error) {
      console.error('Error saving category', error);
      alert('Failed to save category.');
    }
  };

  return (
    <Container fluid="true">
      <Typography variant="h4" gutterBottom>
        Edit Resource Media
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
            <input
              type="file"
              id="fileInput"
              accept=".png"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <Button variant="outlined" component="label" htmlFor="fileInput">
              Upload Image
            </Button>
            <Typography variant="caption" color="textSecondary">
              Dimensions (600px x 350px) in PNG format
            </Typography>
            {imgThumbnail && (
              <div style={{ marginTop: '16px' }}>
                <img
                  src={imgThumbnail}
                  alt="Selected"
                  style={{ width: '600px', maxHeight: '350px', objectFit: 'cover' }}
                />
              </div>
            )}
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
            <InputLabel htmlFor="categorySelect">Resource Category</InputLabel>
            <Select
              id="category<Select>"
              value={resourceCategory}
              onChange={handleCategoryChange}
              label="Category"
            >
              {
                categories.map((cat, index) => (
                  <MenuItem key={`categories-${index}`} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Box>

        <Box mb={2}>
          <FormGroup row sx={{ m: 1, minWidth: 440 }} size="small">
            <FormControlLabel
              key={`isFeatured`}
              control={
                <Checkbox
                  id={`isFeaturedCheck`}
                  checked={isFeatured === 1}
                  value={1}
                  onChange={handleFeaturedChange}
                />
              }
              label="Featured Media"
              sx={{ mr: 2 }}
            />

            <FormControlLabel
              key={`isActive`}
              control={
                <Checkbox
                  id={`isActiveCheck`}
                  checked={isActive === 1}
                  value={1}
                  onChange={handleActiveChange}
                />
              }
              label="Active"
            />
          </FormGroup>
        </Box> 

        <Box mb={2}>
          <FormControl component="fieldset" sx={{ m: 1, minWidth: 440 }} size="small" >
            <Typography variant="h6" gutterBottom>
              Countries
            </Typography>
            <FormGroup>
              {
                countries && countries.length > 0 ? (
                  countries.map((country, index) => (
                    <FormControlLabel
                      key={`country-list-item-${index}`}
                      control={
                        <Checkbox
                          id={`countryCheckbox-${country.id}`}
                          checked={selectedCountries.includes(country.id)}
                          value={country.id}
                          onChange={handleCountryChange}
                        />
                      }
                      label={country.name}
                    />
                  ))) : (<Container />)
              }
            </FormGroup>
          </FormControl>
        </Box>

        <Box mb={2}>
          <FormControl component="fieldset" sx={{ m: 1, minWidth: 440 }} size="small" >
            <InputLabel htmlFor="languageSelect">Language</InputLabel>
            <Select
              id="languageSelect"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              label="Language"
            >
              {
                languages && languages.length > 0 ?
                  (
                    languages.map((language,index) => (
                      <MenuItem
                        key={`language-${index}`}
                        value={language.id}
                      ><em>{language.name}</em></MenuItem>
                    ))
                  ) :
                  (
                    <Container />
                  )
              }
            </Select>
          </FormControl>
        </Box>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </form>
    <EditSubMedia id={id} state={state} mediaList={resourceSubMedia}/>
    </Container>
  );
};

export default EditResourceMedia;
