import React, { useState } from 'react';
import { FormControl } from '@mui/material';
import { DatePicker } from 'antd';
import moment from 'moment';

const DateRangeCalendar = () => {
    const { RangePicker } = DatePicker;
    const [dates, setDates] = useState([]);
    //   console.log(dates);

    return (
        <>
            {/* <FormControl sx={{ marginRight: 1, minWidth: 220 }} >
            <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DemoContainer components={['SingleInputDateRangeField']} >
                <DateRangePicker slots={{ field: SingleInputDateRangeField }} />
            </DemoContainer>
            </LocalizationProvider>
        </FormControl> */}

            <FormControl sx={{ m: 1, minWidth: 230 }}>
                <RangePicker
                    style={{ height: '3.5rem', backgroundColor: '#f8f9fa', color: 'red !important' }}
                    onChange={(values) => {
                        setDates(values.map(item => {
                            return moment(item).format('DD-MM-YYYY')
                        }))
                    }} />
            </FormControl>
        </>
    )
}

export default DateRangeCalendar;