import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { collection, getDocs, doc, deleteDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import { db } from '../../Firebase';


const TABLE_HEAD = [
  { id: 'id', label: 'Number', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: '1' },
  { id: '2' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState([]);
  const [idToDelete, setIdToDelete] = useState({});
  const [open, setOpen] = useState(false);
  // console.log(idToDelete)
  const toggle = () => setOpen(!open);

  useEffect(() => {
    const fetchData = onSnapshot(collection(db, "Users"),
      (snapShot) => {
        const list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (err) => {
        console.log(err)
      }
    );
    return () => {
      fetchData();
    };
  }, []);

  // delete user
  const id = idToDelete.id;
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "Users", id));
      setData(data.filter((item) => item.id !== id));
      toggle()
      const newRowCount = data.length - 1;
      const newPageCount = Math.ceil(newRowCount / rowsPerPage);
      if (page >= newPageCount) {
          setPage(newPageCount - 1);
      }
    } catch (err) {
      toggle()
      // console.log(err)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(data.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const handleFilterByName = (event) => {
  //   setPage(0);
  //   setFilterName(event.target.value);
  // };


  // const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  // const isNotFound = !filteredUsers.length && !!filterName;

  const handleNewUser = () => {
    navigate('/dashboard/newuser', { replace: true })
  }

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewUser} >
            New User
          </Button>
        </Stack>
        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {applySortFilter(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currItem, index) => (
                        <TableRow hover key={index} tabIndex={-1} >
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{currItem.name}</TableCell>
                          <TableCell align="left">{currItem.email}</TableCell>
                          <TableCell align="left">{currItem.country}</TableCell>
                          <TableCell align="left">{currItem.phone}</TableCell>
                          <TableCell>
                            <Link to={`/dashboard/edituser/${currItem.id}`}>
                              <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currItem); toggle(); }}
                            >Delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal isOpen={open} style={{ marginTop: '13rem', }}>
        <ModalHeader toggle={toggle}>User : {idToDelete.name}</ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>No</Button>
          <Button color="primary" onClick={handleDelete}>Yes </Button>
        </ModalFooter>
      </Modal >
    </>
  );
}
