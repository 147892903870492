import * as RiIcons from 'react-icons/ri';
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [

  // community
  {
    title: 'community',
    icon: icon('community'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Community Category',
        icon: icon('ic_blog'),
        path: '/dashboard/communitycategory',
      },
      {
        title: 'Community Topics',
        icon: icon('ic_blog'),
        path: '/dashboard/communitytopic',
      },
    ],
  },
  // resources
  {
    title: 'resources',
    icon: icon('resource'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Resources Category ',
        icon: icon('ic_blog'),
        path: '/dashboard/resourcescategory',
      },
      {
        title: 'Resources Article',
        icon: icon('ic_blog'),
        path: '/dashboard/resourcesarticle',
      },
      {
        title: 'Resources Media',
        icon: icon('ic_blog'),
        path: '/dashboard/resourcesmedia',
      },
    ],
  },
  // quiz
  {
    title: 'quiz',
    path: '/dashboard/quiz',
    icon: icon('quiz'),
  },
  // notifications
  {
    title: 'notifications',
    path: '/dashboard/notification',
    icon: icon('notification'),
  },
  // report
  // {
  //   title: 'reports',
  //   path: '/dashboard/report',
  //   icon: icon('ic_blog'),
  // },

  // dashboard
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  // user
  // {
  //   title: 'users',
  //   icon: icon('ic_user'),
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: 'Users',
  //       icon: icon('ic_blog'),
  //       path: '/dashboard/user'
  //     },
  //     {
  //       title: 'Roles',
  //       icon: icon('ic_blog'),
  //       path: '/dashboard/role'
  //     },
  //   ]
  // },
  // food
  {
    title: 'food',
    icon: icon('food2'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Food Category ',
        icon: icon('food'),
        path: '/dashboard/foodcategory',
      },
      {
        title: 'Food Items ',
        icon: icon('ic_blog'),
        path: '/dashboard/fooditems',
      },
    ],
  },
  // Constant
  // {
  //   title: 'Policies',
  //   path: '/dashboard/viewconstantsvalue/7',
  //   icon: icon('constants'),
  // },
  {
    title: 'Policies',
    // path: '/dashboard/viewconstantsvalue/7',
    icon: icon('policies'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Privacy Policy',
        icon: icon('ic_blog'),
        path: '/dashboard/privacy-policy', // 7 
      },
      {
        title: 'Return/Refund Policy',
        icon: icon('ic_blog'),
        path: '/dashboard/refund-policy', // 11 
      },
      {
        title: 'FAQs',
        icon: icon('ic_blog'),
        path: '/dashboard/viewconstantsvalue/3',
      },
      {
        title: 'Terms of Use',
        icon: icon('ic_blog'),
        path: '/dashboard/terms-of-use', // 9 
      },
    ],
  },
  {
    title: 'Trackers',
    icon: icon('trackers'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Moods',
        icon: icon('ic_blog'),
        path: '/dashboard/moods' // 4 
      }
    ]
  },
  {
    title: 'Onboarding',
    icon: icon('onboard'),
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [{
      title: 'Conception treatment',
      icon: icon('ic_blog'),
      path: '/dashboard/conception-treatment' // 1
    },
    {
      title: 'Exercise type',
      icon: icon('ic_blog'),
      path: '/dashboard/exercise-types' // 2 
    },
    {
      title: 'Periodic Cycle Length',
      icon: icon('ic_blog'),
      path: '/dashboard/period-cycle-length' // 5 
    },
    {
      title: 'Periodic Durationn',
      icon: icon('ic_blog'),
      path: '/dashboard/period-duration' // 6 
    },
    ]
  },
  {
    title: 'Delivery Checklist',
    icon: icon('delivery'),
    path: '/dashboard/deliverychecklist',
  },
  {
    title: 'MileStones',
    icon: icon('milestone'),
    path: '/dashboard/milestonegroup',
  },
  {
    title: 'Vaccination',
    icon: icon('vaccination'),
    path: '/dashboard/vaccination',
  },
  {
    title: 'Countries',
    icon: icon('countries'),
    path: '/dashboard/countries',
  },
  {
    title: 'Languages',
    icon: icon('language'),
    path: '/dashboard/languages',
  },
  // {
  //   title: 'Manage Users',
  //   icon: icon('manageUser'),
  //   path: '/dashboard/manageuser',
  // },
];

export default navConfig;
