import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../Interceptors/Interceptor';

const EditLanguages = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [updatedLanguages, setUpdatedLanguages] = useState({
        name: "", code: ""
    });

    const handleInputs = (e) => {
        setUpdatedLanguages({ ...updatedLanguages, [e.target.name]: e.target.value });
    }

    const PUT_URL = `/v1/languages/${id}`;
    const putLanguages = async () => {
        try {
            const response = await api.put(PUT_URL,
                {
                    name: updatedLanguages.name,
                    code: updatedLanguages.code
                })
            if (response.status === 200) {
                navigate('/dashboard/languages', { replace: true })
                setUpdatedLanguages({ name: "", code: "" })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putLanguages();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadLanguages();
    }, []);

    const loadLanguages = async () => {
        const URL = `/v1/languages/${id}`
        const result = await api.get(URL);
        setUpdatedLanguages(result.data);
    }

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Language
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div >
                        <TextField
                            label="Languages"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedLanguages.name || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Code"
                            name="code"
                            id="code"
                            onChange={handleInputs}
                            value={updatedLanguages.code || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={!updatedLanguages.name}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditLanguages;