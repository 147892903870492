import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditConstantValue = () => {
  const { categoryId, id } = useParams();
  const navigate = useNavigate();
  const [viewConstTypeName, setViewConstTypeName] = useState({});
  const [editConstantValue, setEditConstantValue] = useState({
    value: ""
  });

  const handleInputs = (e) => {
    setEditConstantValue({ ...editConstantValue, [e.target.name]: e.target.value })
  }

  // put new api call
  const PUT_URL = `/v1/constants/${id}`;
  const putConstantValue = async () => {
    try {
      const response = await api.put(PUT_URL, {
        value: editConstantValue.value,
        type: categoryId,
      })
      if (response.status === 200) {
        navigate('/dashboard/constanttypes', { replace: true })
      }
    } catch (err) {
      // console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    putConstantValue();
  };

  // get priveous data on edit page
  useEffect(() => {
    loadConstanttypes();
    loadConstantType();
  }, []);

  const loadConstanttypes = async () => {
    const URL = `/v1/constants/${id}`
    const result = await api.get(URL);
    setEditConstantValue(result.data);
  }

  // for name display
  const loadConstantType = async () => {
    const URL = `/v1/constantTypes/${categoryId}`
    const result = await api.get(URL);
    setViewConstTypeName(result.data);
  }

  const inputMissing = () => !(editConstantValue.value);
  return (
    <>
      <Container sx={{ display: 'table-caption' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Constant Value : ( {viewConstTypeName.name} )
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="Constant Value"
              name="value"
              id="value"
              onChange={handleInputs}
              value={editConstantValue.value || ""}
            />
          </div>
          <Button variant="contained" style={{ marginLeft: '10px' }} disabled={inputMissing()} onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default EditConstantValue;
