import { Button, Container, TextField, Box, Stack, Typography } from '@mui/material';
import { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditDeliveryValue = () => {
    const { categoryId, id } = useParams();
    const navigate = useNavigate();
    const [viewDeliveryListName, setViewDeliveryListName] = useState({});
    const [updatedDeliveryChecklist, setUpdatedDeliveryChecklist] = useState({ name: '' })

    const handleInputs = (e) => {
        setUpdatedDeliveryChecklist({ ...updatedDeliveryChecklist, [e.target.name]: e.target.value });
    }
    // add new api
    const PUT_URL = `/v1/deliveryChecklists/${id}`;
    const putEditDeliveryValue = async () => {
        try {
            const response = await api.put(PUT_URL,
                {
                    name: updatedDeliveryChecklist.name,
                    delivery_checklist_group_id: categoryId
                })
            if (response.status === 200) {
                navigate('/dashboard/deliverychecklist', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putEditDeliveryValue();
    }

    // for name show
    useEffect(() => {
        loadDeliveryValue();
        loadDeliveryChecklist();
    }, []);

    const loadDeliveryValue = async () => {
        const URL = `/v1/deliveryChecklistGroups/${categoryId}`
        const result = await api.get(URL)
        setViewDeliveryListName(result.data)
    }

    // get priveous data on edit page

    const loadDeliveryChecklist = async () => {
        const URL = `/v1/deliveryChecklists/${id}`
        const result = await api.get(URL);
        setUpdatedDeliveryChecklist(result.data)
    }
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Delivery Value : ( {viewDeliveryListName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >

                    <div >
                        <TextField
                            label="Delivery Checklist"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedDeliveryChecklist.name || ""}
                        />
                    </div>
                    <Button variant="contained" style={{ marginLeft: '10px' }}
                        disabled={!updatedDeliveryChecklist.name} onClick={handleSubmit}>
                        Update
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default EditDeliveryValue;
