import axios from 'axios';

let token = localStorage.getItem("token");
if(token){
    token = token.replace(/"/g, '');
}

const api = axios.create({
    // baseURL: 'http://192.168.18.31:4000',
    // baseURL: 'https://momsup-dev-dot-momsup-v2-dev.as.r.appspot.com',
    baseURL: 'https://momsup-v2-prod.as.r.appspot.com/',
    headers: token? {
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': `Bearer ${token}`
    } : {
        'Content-Type': 'application/json',
    }
});

//  request interceptor
api.interceptors.request.use((config) => {
    return config;
},
    (error) => {
        return Promise.reject(error);
    });

//  response interceptor
api.interceptors.response.use((response) => {
    return response;
},
    (error) => {
        return Promise.reject(error);
    });

export default api;