import React from 'react'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

const LanguagePage = () => {
    return (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel>Language</InputLabel>
            <Select
                id="demo-simple-select-helper"
                label="Language"
            // onChange={handleChange}
            >
                <MenuItem >English</MenuItem>
                <MenuItem >French</MenuItem>
                <MenuItem >Chinease</MenuItem>
            </Select>
        </FormControl>
    )
}

export default LanguagePage