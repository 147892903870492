import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Card, Table, Stack, Button, TableRow,
    TableBody, TableCell, Container, Typography,
    TableContainer, FormControl, TextField
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import api from '../../../Interceptors/Interceptor';
import { UserListHead } from '../../../sections/@dashboard/user';



const TABLE_HEAD = [
    { id: 'value', label: 'Value', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function ViewMultiValueConstant({ id }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('value');
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState({});
    const [selectedId, setSelectedId] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [updatedConstant, setUpdatedConstant] = useState('');

    const location = useLocation();
    const [openDelete, setOpenDelete] = useState(false);
    const toggleDelete = () => setOpenDelete(!openDelete);
    const [openEdit, setOpenEdit] = useState(false);
    const toggleEdit = () => setOpenEdit(!openEdit);
    const [openAdd, setOpenAdd] = useState(false);
    const toggleAdd = () => setOpenAdd(!openAdd);


    // get api call
    useEffect(() => {
        const getConstantType = async () => {
            const GET_URL = `/v1/constant-type/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data
            } catch (err) {
                // console.log(err)
            }
            return null;
        };

        const getConstantData = async () => {
            const GET_URL = `/v1/constant/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data;
            } catch (err) {
                // console.log(err)
            }
            return null;
        };
        getConstantType()
            .then((constantType) => {
                getConstantData()
                    .then((constantData) => {
                        const dataObj = {
                            typeId: constantType.id,
                            typeName: constantType.name,
                            dataIds: constantData.map(v => v.id),
                            values: constantData.map(v => v.value)
                        }
                        setData(dataObj);
                    })
            });
    }, []);


    // delete api call
    const handleDelete = async () => {
        const DELETE_URL = `/v1/constant/${selectedId}`
        try {
            const response = await api.delete(DELETE_URL)
            toggleDelete();
            // const newRowCount = viewConstantsType.length - 1;
            // const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            // if (page >= newPageCount) {
            //     setPage(newPageCount - 1);
            // }
        } catch (err) {
            toggleDelete();
            // console.log(err)
        }
    }

    // edit api call
    const handleConstantNameChange = (e) => {
        setUpdatedConstant(e.target.value);
    };

    const handleUpdate = async () => {
        const URL = `/v1/constant/${selectedId}`
        try {
            const response = await api.put(URL, { value: updatedConstant });
            const dataValues = [...data.values];
            dataValues[selectedIndex].value = updatedConstant;
            data.values = dataValues;
            setData({
                ...data
            });
            setUpdatedConstant(null);
            toggleEdit();
        } catch (err) {
            toggleEdit();
            // console.log(err)
        }
    }
    
    const handleAdd = async () => {
        const URL = `/v1/constant`
        try {
            const response = await api.post(URL, { 
                type: data.typeId,
                value: updatedConstant 
            });
            console.log(response);
            const dataValues = [...data.values];
            const dataIds = [...data.dataIds];
            dataValues.push(response.data.value);
            dataIds.push(response.data.id);
            data.values = dataValues;
            data.dataIds = dataIds;
            console.log('updated',data);
            setData({
                ...data
            });
            setUpdatedConstant(null);
            alert("Data added successfully");
            toggleAdd();
        } catch (err) {
            toggleAdd();
            // console.log(err)
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(data.dataIds.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.dataIds.length) : 0;


    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data.typeName}
                    </Typography>
                    <Button onClick={()=>{setUpdatedConstant('');toggleAdd();}} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                        Add Value
                    </Button>
                </Stack>
                <Card>
                    {
                        data.dataIds && data.dataIds.length > 0 ?
                            (
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 600 }}>
                                        <Table>
                                            <UserListHead
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={data.dataIds.length}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {
                                                    applySortFilter(data.values, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((value, index) => (
                                                            <TableRow hover key={index}>
                                                                <TableCell align="left">{value}</TableCell>
                                                                <TableCell>
                                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setSelectedIndex(index); setSelectedId(data.dataIds[index]); setUpdatedConstant(data.values[index]); toggleEdit(); }}>Edit</Button>
                                                                    <Button variant='contained' sx={{ mt: 1, ml: 1 }} onClick={() => { setSelectedIndex(index); setSelectedId(data.dataIds[index]); toggleDelete(); }}
                                                                    >Delete</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={3} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>
                            )
                            : (<Container />)
                    }
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal
                isOpen={openDelete} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggleDelete}>Delete</ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggleDelete}> No</Button>
                    <Button color="primary" onClick={handleDelete}> Yes </Button>
                </ModalFooter>
            </Modal >

            {/* modal for edit item */}
            <Modal
                isOpen={openEdit} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
                <ModalBody>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Value"
                            name="value"
                            id="value"
                            value={updatedConstant}
                            onChange={handleConstantNameChange}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={handleUpdate}>Confirm</Button>
                    <Button color="primary" onClick={() => { setSelectedId(null); setSelectedIndex(null); toggleEdit(); }}> Cancel </Button>
                </ModalFooter>
            </Modal >
            
            {/* modal for add item */}
            <Modal
                isOpen={openAdd} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggleAdd}>Add</ModalHeader>
                <ModalBody>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Value"
                            name="value"
                            id="value"
                            value={updatedConstant}
                            onChange={handleConstantNameChange}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={handleAdd}>Confirm</Button>
                    <Button color="primary" onClick={() => { setSelectedId(null); setSelectedIndex(null); toggleEdit(); }}> Cancel </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
