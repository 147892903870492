import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const NewFoodItem = () => {
  const navigate = useNavigate();
  const [foodCategoryName, setFoodCategoryName] = useState([]);
  const [file, setFile] = useState('');
  const [newFoodItem, setNewFoodItem] = useState({
    name: '',
    baby: '',
    breastfeeding: '',
    afterbirth: '',
    pregnancy: '',
    content: '',
    food_category: '',
  });

  const handleInputs = (e) => {
    setNewFoodItem({ ...newFoodItem, [e.target.name]: e.target.value });
  };

  const STATUSES = {
    1: { label: 'Unsafe', color: '#E53935' },
    2: { label: 'Tiny Portions Only', color: '#1E88E5' },
    3: { label: 'Caution', color: '#FB8C00' },
    4: { label: 'Safe', color: '#43A047' },
  };

  const getBackgroundColor = (value) => {
    return STATUSES[value] ? STATUSES[value].color : '#FFFFFF';
  };

  // post image api
  const formData = new FormData();
  formData.append('image', file);
  formData.append('path', 'food-items');

  const POST_IMAGE = `/v1/upload-image`;
  const POST_URL = `/v1/food-items`;
  const fetchData = async () => {
    try {
      const response1 = await api.post(POST_IMAGE, formData);
      if (response1.status !== 200) {
        throw new Error('Failed to fetch data');
      }

      if (response1.status === 200) {
        const response2 = await api.post(POST_URL, {
          image_url: response1.data.image_url,
          name: newFoodItem.name,
          baby: newFoodItem.baby,
          breastfeeding: newFoodItem.breastfeeding,
          afterBirth: newFoodItem.afterbirth,
          pregnancy: newFoodItem.pregnancy,
          content: newFoodItem.content,
          food_category_id: newFoodItem.food_category,
          active: true,
        });
        if (response2.status !== 200) {
          throw new Error('Failed to fetch data');
        }
      }
      navigate('/dashboard/fooditems', { replace: true });
      setNewFoodItem({
        name: '',
        baby: '',
        breastfeeding: '',
        afterbirth: '',
        pregnancy: '',
        content: '',
        food_category: '',
      });
      setFile('');
    } catch (err) {
      //  console.log(err)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  // get api call
  useEffect(() => {
    getFoodCategory();
  }, []);

  const getFoodCategory = async () => {
    const GET_URL = `/v1/food-categories`;
    try {
      const response = await api.get(GET_URL);
      setFoodCategoryName(response.data);
    } catch (err) {
      // console.log(err)
    }
  };

  const inputMissing = () => !(newFoodItem.name && newFoodItem.pregnancy);
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifydescription="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            New Food Item
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div className='d-flex flex-column'>
            <TextField
              // label="Thumbnail"
              type="file"
              name="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            
            <Typography variant="caption" color="textSecondary" ml={1} mb={2}>
              Dimensions (512px x 512px) in PNG format
            </Typography>

          </div>
          <div>
            <TextField label="Food Name" name="name" id="name" onChange={handleInputs} value={newFoodItem.name || ''} />
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
              <InputLabel >Baby</InputLabel>
              <Select
                id="baby"
                label="Baby"
                name="baby"
                sx={{ p: 1 }}
                value={newFoodItem.baby || ''}
                onChange={handleInputs}
              >
                {Object.keys(STATUSES).map((key) => (
                  <MenuItem key={key} value={key}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(key),
                          borderRadius: '50%',
                          height: '10px',
                          width: '10px',
                          marginRight: '8px',
                        }}
                      />  {STATUSES[key].label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
              <InputLabel >Breast Feeding</InputLabel>
              <Select
                id="breastfeeding"
                label="Breast Feeding"
                name="breastfeeding"
                sx={{ p: 1 }}

                value={newFoodItem.breastfeeding || ''}
                onChange={handleInputs}
              >
                {Object.keys(STATUSES).map((key) => (
                  <MenuItem key={key} value={key}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(key),
                          borderRadius: '50%',
                          height: '10px',
                          width: '10px',
                          marginRight: '8px',
                        }}
                      />  {STATUSES[key].label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
              <InputLabel >Pregnancy</InputLabel>
              <Select

                id="pregnancy"
                label="Pregnancy"
                name="pregnancy"
                sx={{ p: 1 }}

                value={newFoodItem.pregnancy || ''}
                onChange={handleInputs}
              >
                {Object.keys(STATUSES).map((key) => (
                  <MenuItem key={key} value={key}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(key),
                          borderRadius: '50%',
                          height: '10px',
                          width: '10px',
                          marginRight: '8px',
                        }}
                      /> {STATUSES[key].label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
              <InputLabel >After Birth</InputLabel>
              <Select

                id="afterbirth"
                label="After Birth"
                name="afterbirth"
                sx={{ p: 1 }}

                value={newFoodItem.afterbirth || ''}
                onChange={handleInputs}
              >
                {Object.keys(STATUSES).map((key) => (
                  <MenuItem key={key} value={key}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(key),
                          borderRadius: '50%',
                          height: '10px',
                          width: '10px',
                          marginRight: '8px',
                        }}
                      />  {STATUSES[key].label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
              <InputLabel>Food Category</InputLabel>
              <Select
                id="food_category"
                label="Food Category"
                name="food_category"
                sx={{ p: 1 }}
                value={newFoodItem.food_category || ''}
                onChange={handleInputs}
              >
                {foodCategoryName.map((foodName, index) => (
                  <MenuItem key={index} value={foodName.id}>
                    {foodName.name}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              style={{ width: 800 }}
              label="Description"
              name="content"
              id="content"
              onChange={handleInputs}
              value={newFoodItem.content || ''}
            />
          </div>
          <Button variant="contained" style={{ marginLeft: '10px' }} disabled={inputMissing()} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default NewFoodItem;