import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditVaccine = () => {
    const { countryid, dataid, id } = useParams();
    const navigate = useNavigate();
    const [viewVaccineName, setViewVaccineName] = useState({});
    const [countryName, setCountryName] = useState([]);
    const [editVaccine, setEditVaccine] = useState({
        description: "", name: ""
    });
    const handleInputs = (e) => {
        setEditVaccine({ ...editVaccine, [e.target.name]: e.target.value })
    }

    // put new api call
    const PUT_URL = `/v1/vaccines/${id}`;
    const putVaccine = async () => {
        try {
            const response = await api.put(PUT_URL, {
                name: editVaccine.name,
                description: editVaccine.description,
                vaccination_time_id: dataid,
            })
            if (response.status === 200) {
                navigate(`/dashboard/viewvaccine/${countryid}/${dataid}`, { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putVaccine();
    };

    // get priveous data on edit page
    useEffect(() => {
        loadVaccine();
        loadVaccineName();
        getcountryName();
    }, []);

    const loadVaccine = async () => {
        const URL = `/v1/vaccines/${id}`
        const result = await api.get(URL);
        setEditVaccine(result.data)
    }

    // for vaccine name display
    const loadVaccineName = async () => {
        const URL = `/v1/vaccinationTime/${dataid}`
        const result = await api.get(URL)
        setViewVaccineName(result.data)
    }

    // get api call country name
    const getcountryName = async () => {
        const GET_URL = `/v1/countries/${countryid}`
        try {
            const response = await api.get(GET_URL)
            setCountryName(response.data);
        } catch (err) {
            // console.log(err)
        }
    };
    const inputMissing = () => !(editVaccine.description && editVaccine.name);
    return (
        <>
            <Container sx={{ display: 'table-caption' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Vaccine : ( {countryName.name}/{viewVaccineName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={editVaccine.name || ""}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={editVaccine.description || ""}
                        />
                    </div>
                    <Button variant="contained" style={{ marginLeft: '10px' }} disabled={inputMissing()} onClick={handleSubmit}>
                        Update
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default EditVaccine;
