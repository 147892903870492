import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import api from '../../../Interceptors/Interceptor';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'image', label: 'Image', alignRight: false },
    { id: 'name', label: 'Food Name', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'baby', label: 'Baby', alignRight: false },
    { id: 'breastfeeding', label: 'Breast Feeding', alignRight: false },
    { id: 'afterBirth', label: 'AfterBirth', alignRight: false },
    { id: 'pregnancy', label: 'Pregnancy', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function FoodItems() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [foodItem, setFoodItem] = useState([]);
    const [idToDelete, setIdToDelete] = useState({});
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);

    useEffect(() => {
        getFoodItem();
    }, []);

    const getFoodItem = async () => {
        const GET_URL = `/v1/food-items`;
        try {
            const response = await api.get(GET_URL);
            setFoodItem(response.data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // Handle error
        }
    };

    const handleDelete = async () => {
        const DELETE_URL = `/v1/food-items/${idToDelete.id}`;
        try {
            await api.delete(DELETE_URL);
            getFoodItem();
            toggle();
            const newRowCount = foodItem.length - 1;
            const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            if (page >= newPageCount) {
                setPage(newPageCount - 1);
            }
        } catch (err) {
            toggle();
            // Handle error
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(foodItem.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - foodItem.length) : 0;

    const getBackgroundColor = (value) => {
        switch (value) {
            case 1:
                return "#E53935"; // Red for Unsafe
            case 2:
                return "#1E88E5"; // Blue for Caution
            case 3:
                return "#FB8C00"; // Orange for Tiny Portions Only
            default:
                return "#43A047"; // Green for Safe
        }
    };
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Food Items
                    </Typography>
                    <Link to={`/dashboard/newfooditem`}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Add Food Item
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={foodItem.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {applySortFilter(foodItem, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currFoodItems, index) => (
                                            <TableRow hover key={index} >
                                                <TableCell align="left" >{(index + 1 + rowsPerPage * page)}</TableCell>
                                                <TableCell component="th" scope="row" padding="none" >
                                                    <Stack direction="row" alignItems="center" spacing={2} >
                                                        <Avatar alt={currFoodItems.name} src={currFoodItems.image_url} sx={{ ml: 2 }} style={{ width: "80px", height: "50px", borderRadius: '0px' }} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{currFoodItems.name}</TableCell>
                                                <TableCell align="left"  >{currFoodItems.content}</TableCell>

                                                {/* Baby status */}
                                                <TableCell align="left">
                                                    <div style={{
                                                        background: getBackgroundColor(currFoodItems.baby),
                                                        textAlign: "center",
                                                        height: "50px",
                                                        width: "50px",
                                                        borderRadius: "100%",
                                                        paddingTop: '14px',
                                                        alignItems: "center",
                                                        color: "white",
                                                    }} />
                                                </TableCell>

                                                {/* Breastfeeding status */}
                                                <TableCell align="left">
                                                    <div style={{
                                                        background: getBackgroundColor(currFoodItems.breastfeeding),
                                                        textAlign: "center",
                                                        height: "50px",
                                                        width: "50px",
                                                        borderRadius: "100%",
                                                        paddingTop: '14px',
                                                        alignItems: "center",
                                                        color: "white",
                                                    }} />
                                                </TableCell>

                                                {/* AfterBirth status */}
                                                <TableCell align="left">
                                                    <div style={{
                                                        background: getBackgroundColor(currFoodItems.afterBirth),
                                                        textAlign: "center",
                                                        height: "50px",
                                                        width: "50px",
                                                        borderRadius: "100%",
                                                        paddingTop: '14px',
                                                        alignItems: "center",
                                                        color: "white",
                                                    }} />
                                                </TableCell>

                                                {/* Pregnancy status */}
                                                <TableCell align="left">
                                                    <div style={{
                                                        background: getBackgroundColor(currFoodItems.pregnancy),
                                                        textAlign: "center",
                                                        height: "50px",
                                                        width: "50px",
                                                        borderRadius: "100%",
                                                        paddingTop: '14px',
                                                        alignItems: "center",
                                                        color: "white",
                                                    }} />
                                                </TableCell>
                                                {/* Edit button */}
                                                <TableCell>
                                                    <Link to={`/dashboard/editfooditem/${currFoodItems.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                                                    </Link>
                                                </TableCell>
                                                {/* Delete button */}
                                                <TableCell>
                                                    <Button
                                                        variant='contained'
                                                        sx={{ mt: 1 }}
                                                        onClick={() => { setIdToDelete(currFoodItems); toggle(); }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={foodItem.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal isOpen={open} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggle}>Topic : {idToDelete.name}</ModalHeader>
                <ModalBody>Are you sure you want to delete?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>No</Button>
                    <Button color="primary" onClick={handleDelete}>Yes </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
