import {
    Button,
    Container,
    TextField,
    Box
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
// import { set, ref, onValue, remove, update } from "firebase/database";
import { doc, serverTimestamp, setDoc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import { ref, onValue, set, remove, update } from "firebase/storage";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db, storage } from '../../Firebase';

const EditUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const [data, setData] = useState([]);
    const [editdata, setEditData] = useState({
        name: "", email: "", phone: "", password: "", country: ""
    });
    // console.log(users)
    // console.log(id)

    const handleInput = (e) => {
        const { value, name } = e.target;
        setEditData({ ...editdata, [name]: value })
    }

    // useEffect(() => {
    //     const fetchData = onSnapshot(collection(db, "Users"),
    //         (snapShot) => {
    //             const list = [];
    //             snapShot.docs.forEach((doc) => {
    //                 list.push({ id: doc.id, ...doc.data() });
    //             });
    //             setData(list);
    //             setEditData(list)
    //         },
    //         (err) => {
    //             console.log(err)
    //         }
    //     );
    //     return () => {
    //         fetchData();
    //     };
    // }, []);

    useEffect(() => {
        loadUser()
    }, []);

    const loadUser = async () => {
        const GET_URL = `/v1/users/${id}`
        // const GET_URL = `/v1/food-items`
        try {
            const response = await axios.get(GET_URL)
            setUsers(response.data)
            // console.log(response.data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // console.log(err)
        }
        // const result = await axios.get(URL);
        // setUsers(result)
        // console.log(result)
    }

    const hanldeAddUser = async (e, id) => {
        e.preventDefault()
        // console.log('click')
        const docRef = doc(db, 'Users', id);

        const updatedata = await {
            Users: editdata
        };

        await setDoc(docRef, updatedata, { merge: true })
            .then(console.log("Data changed successfully"))

        // setisUpdate(false)
        setEditData("")
    }
    // const hanldeAddUser = async (id) => {
    //     try {
    //         const res = await createUserWithEmailAndPassword(
    //             editdata.email, editdata.password, editdata.name, editdata.phone, editdata.country
    //         )
    //         await setDoc(doc(db, "Users", id), {
    //             ...editdata
    //         })
    //         navigate('/dashboard/user', { replace: true })
    //         console.log(res)
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // }


    const inputMissing = () => !(editdata.name && editdata.email && editdata.password && editdata.country);

    return (
        <>
            <Container>
                <h1>Edit User</h1>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Name"
                            name="name"
                            onChange={handleInput}
                            required
                            placeholder='Name'
                            value={editdata.name}
                            error
                        />
                        <TextField
                            label="Email"
                            name="email"
                            onChange={handleInput}
                            required
                            placeholder='abc@gmail.com'
                            value={editdata.email}
                            error
                        />
                    </div>
                    <div>
                        <TextField
                            label="Country"
                            name="country"
                            onChange={handleInput}
                            required
                            placeholder='Country'
                            value={editdata.country}
                            error
                        />
                        <TextField
                            label="Password"
                            name="password"
                            onChange={handleInput}
                            required
                            placeholder='Minimum 6 digit'
                            value={editdata.password}
                            error={editdata.password !== 6}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Phone Number"
                            name="phone"
                            type='number'
                            onChange={handleInput}
                            required
                            value={editdata.phone}
                            placeholder='Minimum 10 digit'
                        />
                    </div>
                    <Button disabled={inputMissing()} variant='contained'
                        style={{ marginLeft: "10px" }}
                        onClick={hanldeAddUser}>Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditUser;