import React from 'react'

const UsersRoles = () => {
    return (
        <>
            <h1>Roles</h1>
        </>
    )
}

export default UsersRoles
