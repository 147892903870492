import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Card, Table, Stack, Button, TableRow,
    TableBody, TableCell, Container, Typography,
    TableContainer, TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: '1' },
    { id: '2' },
    { id: '3' },
];

export default function ViewVaccine() {
    const { countryid, id } = useParams()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [viewVaccine, setViewVaccine] = useState([]);
    const [idToDelete, setIdToDelete] = useState({});
    const [countryName, setCountryName] = useState([]);
    const [vaccineName, setVaccineName] = useState({});
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    // get api call
    // for vaccine name display
    useEffect(() => {
        getViewVaccine();
        loadVaccineGP();
        getcountryName();
    }, []);

    const getViewVaccine = async () => {
        const GET_URL = `/v1/get-vaccines-in-vaccination-time/${id}`
        try {
            const response = await api.get(GET_URL);
            const sortedResponse = response.data.sort((a, b) => {
                const dateA = new Date(a.id);
                const dateB = new Date(b.id);
                return dateA - dateB;
            });
            setViewVaccine(sortedResponse);
        } catch (err) {
            // console.log(err)
        }
    }

    // delete api call
    const handleDelete = async () => {
        const DELETE_URL = `/v1/vaccines/${idToDelete.id}`
        try {
            const response = await api.delete(DELETE_URL);
            getViewVaccine();
            toggle();
            const newRowCount = viewVaccine.length - 1;
            const newPageCount = Math.ceil(newRowCount / rowsPerPage);
            if (page >= newPageCount) {
                setPage(newPageCount - 1);
            }
        } catch (err) {
            toggle();
            // console.log(err)
        }
    }

    const loadVaccineGP = async () => {
        const GET_URL = `/v1/vaccinationTime/${id}`
        try {
            const response = await api.get(GET_URL);
            setVaccineName(response.data)
        } catch (err) {
            // console.log(err)
        }
    }

    // get api call country name
    const getcountryName = async () => {
        const GET_URL = `/v1/countries/${countryid}`
        try {
            const response = await api.get(GET_URL);
            setCountryName(response.data);
        } catch (err) {
            // console.log(err)
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPageCount = Math.ceil(viewVaccine.length / newRowsPerPage);
        const newPage = Math.min(page, newPageCount - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - viewVaccine.length) : 0;

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Vaccine : ( {countryName.name}/{vaccineName.name})
                    </Typography>
                    <Link to={`/dashboard/newVaccine/${countryid}/${id}`} >
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Vaccine
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 600 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={viewVaccine.length}
                                />
                                <TableBody>
                                    {viewVaccine.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((currVaccine, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell align="left">{(index + 1 + rowsPerPage * page)}</TableCell>
                                                <TableCell align="left">{currVaccine.name}</TableCell>
                                                <TableCell align="left">{currVaccine.description}</TableCell>
                                                <TableCell>
                                                    <Link to={`/dashboard/editVaccine/${countryid}/${id}/${currVaccine.id}`}>
                                                        <Button variant='contained' sx={{ mt: 1 }} >Edit</Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currVaccine); toggle(); }}
                                                    >Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[0, 25]}
                        component="div"
                        count={viewVaccine && viewVaccine.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            {/* modal for delete item */}
            <Modal
                isOpen={open} style={{ marginTop: '13rem' }}>
                <ModalHeader toggle={toggle}>Age Group : {idToDelete.name} </ModalHeader>
                <ModalBody>Sure, You want to Delete? </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={toggle}> No</Button>
                    <Button color="primary" onClick={handleDelete}> Yes </Button>
                </ModalFooter>
            </Modal >
        </>
    );
}
