import { Button, Container, TextField, Box, Typography, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewCommunityTopic = () => {
    const navigate = useNavigate();
    const [communityCategoryName, setCommunityCategoryName] = useState([]);
    const [newCommunityTopic, setNewCommunityTopic] = useState({
        topic: "", name: "", categoryid: ""
    });

    const handleInputs = (e) => {
        setNewCommunityTopic({ ...newCommunityTopic, [e.target.name]: e.target.value })
    }

    // post new category api call

    const POST_URL = `/v1/community-topics`;
    const postCommunityTopic = async () => {
        try {
            const response = await api.post(POST_URL, {
                name: newCommunityTopic.topic,
                created_by: newCommunityTopic.name,
                community_category_id: newCommunityTopic.categoryid,
                active: true
            })
            if (response.status === 200) {
                navigate('/dashboard/communitytopic', { replace: true })
                setNewCommunityTopic({ topic: "", name: "", categoryid: "" })
            } else {
                const error = new Error(response.error);
                throw error;
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postCommunityTopic();
        setNewCommunityTopic({ topic: "", name: "", categoryid: "" })
    }

    useEffect(() => {
        getCommunityCategory();
    }, []);

    // get api call
    const getCommunityCategory = async () => {
        const GET_URL = `/v1/community-categories`
        try {
            const response = await api.get(GET_URL)
            setCommunityCategoryName(response.data)
        } catch (err) {
            // console.log(err)
        }
    };

    const inputMissing = () => !(newCommunityTopic.name && newCommunityTopic.topic && newCommunityTopic.categoryid);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Community Topic
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Topic"
                            name="topic"
                            id="topic"
                            onChange={handleInputs}
                            value={newCommunityTopic.topic || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Created_by"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newCommunityTopic.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel>Community Category</InputLabel>
                            <Select
                                id="categoryid"
                                label="Community Category"
                                name='categoryid'
                                sx={{ p: 1 }}
                                value={newCommunityTopic.categoryid || ""}
                                onChange={handleInputs}
                            >
                                {communityCategoryName.map((foodName, index) => (
                                    <MenuItem key={index} value={foodName.id}>{foodName.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewCommunityTopic;