import { Button, Container, TextField, Box, Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const NewCommunityCategory = () => {
    const navigate = useNavigate();
    const state = useLocation();

    const [file, setFile] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [stage, setStage] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);


    useEffect(() => {
        // Fetch countries list from the API on component mount
        const fetchCountries = async () => {
            const GET_URL = `/v1/get-all-countries`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries', error);
            }
        };

        fetchCountries();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'image/png') {
            setFile(selectedFile);
        } else {
            alert('Please select a PNG image.');
        }
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value.slice(0, 30));
    };

    const handleStageChange = (e) => {
        setStage(e.target.value);
    };

    const handleCountryChange = (e) => {
        const { value, checked } = e.target;
        console.log(value,checked);
        if(checked){
            const prevCountries = [...selectedCountries];
            setSelectedCountries([...prevCountries,value]);
        }else{
            setSelectedCountries((prev) => prev.filter(country => country !== value))
        }
        console.log(selectedCountries)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName || !stage || selectedCountries.length === 0) {
            alert('Please fill all fields.');
            return;
        }

        const formData = new FormData();
        if (file) formData.append('image', file);
        formData.append('path', 'community-categories');

        try {
            const resImg = await api.post(`/v1/upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (resImg.status !== 200) {
                throw new Error("Failed to save image");
            }

            const resCommCat = await api.post(`/v1/community-categories`,
                {
                    image_url: resImg.data.image_url,
                    name: categoryName,
                    stage,
                    active: true
                });
            if (resCommCat.status !== 200) {
                throw new Error("Failed to save data");
            }

            const resCommCatCountries = await api.post(`/v1/community-category-countries`,
                {
                    categoryId: resCommCat.data.id,
                    countries: JSON.stringify(selectedCountries)
                });
            if (resCommCatCountries.status !== 200) {
                throw new Error("Failed to save countires");
            }

            alert('Category saved successfully!');

            navigate('/dashboard/communitycategory', {
                replace: true,
                state: {
                    stage: state.state.stage,
                }
            });

            setFile(null);
            setCategoryName("");
            setStage("");
            setCountries([]);
            setSelectedCountries([]);

        } catch (error) {
            console.error('Error saving category', error);
            alert('Failed to save category.');
        }
    };

    return (
        <Container fluid="true">
            <Typography variant="h4" gutterBottom>
                New Community Category
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                        <input
                            type="file"
                            id="fileInput"
                            accept=".png"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <Button variant="outlined" component="label" htmlFor="fileInput">
                            Upload Image
                        </Button>
                        <Typography variant="caption" color="textSecondary">
                            Dimensions (60px x 60px) in PNG format
                        </Typography>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Category Name"
                        id="categoryNameInput"
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        inputProps={{ maxLength: 30 }}
                        sx={{ m: 1, minWidth: 440 }} size="small"
                    />
                </Box>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
                        <InputLabel htmlFor="stageSelect">Stage</InputLabel>
                        <Select
                            id="stageSelect"
                            value={stage}
                            onChange={handleStageChange}
                            label="Stage"
                        >
                            <MenuItem value=""><em>Select a stage</em></MenuItem>
                            <MenuItem value="1">Pre-pregnancy</MenuItem>
                            <MenuItem value="2">Pregnancy</MenuItem>
                            <MenuItem value="3">Parenting</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <FormControl component="fieldset">
                        <Typography variant="h6" gutterBottom>
                            Countries
                        </Typography>
                        <FormGroup>
                            {countries.map(country => (
                                <FormControlLabel
                                    key={country.id}
                                    control={
                                        <Checkbox
                                            id={`countryCheckbox-${country.id}`}
                                            value={country.id}
                                            onChange={handleCountryChange}
                                        />
                                    }
                                    label={country.name}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Box>
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </form>
        </Container>
    );
};

export default NewCommunityCategory;