import React from 'react'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

const CountryPage = () => {
    return (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel>Country</InputLabel>
            <Select
                id="demo-simple-select-helper"
                label="Country"
                // onChange={handleChange}
            >
                <MenuItem >India</MenuItem>
                <MenuItem >Hongkong</MenuItem>
                <MenuItem >Indonesia</MenuItem>
                <MenuItem >Malaysia</MenuItem>
                <MenuItem >Philippines</MenuItem>
                <MenuItem >Singapore</MenuItem>
            </Select>
        </FormControl>
    )
}

export default CountryPage