import React from 'react'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

const AcquisitionPage = () => {
    return (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel>Acquisition Cha</InputLabel>
            <Select
                label="Acquisition Cha"
            // onChange={handleChange}
            >
                <MenuItem >Ten</MenuItem>
                <MenuItem >Twenty</MenuItem>
                <MenuItem >Thirty</MenuItem>
            </Select>
        </FormControl>)
}

export default AcquisitionPage