import { Checkbox } from '@mui/material';
import { ModalBody } from "reactstrap";

const QueAnsReadonly = ({ viewQusOptions }) => (
    <ModalBody>
        {viewQusOptions.option_1 && viewQusOptions.answers !== null ?
            (<div style={{ display: 'flex' }}>
                <Checkbox
                    readOnly
                    // eslint-disable-next-line react/prop-types
                    checked={viewQusOptions.answers.indexOf('1') >= 0}
                />
                <h6 className=' mt-2' style={{ marginLeft: "7px", color: `${viewQusOptions.answers.indexOf('1') >= 0 ? 'green' : '#212B36'}` }} >{viewQusOptions.option_1}</h6>
            </div>) : ""}

        {viewQusOptions.option_2 && viewQusOptions.answers !== null ?
            (<div style={{ display: 'flex' }}>
                <Checkbox
                    readOnly
                    // eslint-disable-next-line react/prop-types
                    checked={viewQusOptions.answers.indexOf('2') >= 0}

                />
                <h6 className=' mt-2' style={{ marginLeft: "7px", color: `${viewQusOptions.answers.indexOf('2') >= 0 ? 'green' : '#212B36'}` }} >{viewQusOptions.option_2}</h6>
            </div>) : ""}

        {viewQusOptions.option_3 && viewQusOptions.answers !== null ?
            (<div style={{ display: 'flex' }}>
                <Checkbox
                    readOnly
                    // eslint-disable-next-line react/prop-types
                    checked={viewQusOptions.answers.indexOf('3') >= 0}

                />
                <h6 className=' mt-2' style={{ marginLeft: "7px", color: `${viewQusOptions.answers.indexOf('3') >= 0 ? 'green' : '#212B36'}` }} >{viewQusOptions.option_3}</h6>
            </div>) : ""}

        {viewQusOptions.option_4 && viewQusOptions.answers !== null ?
            (<div style={{ display: 'flex' }}>
                <Checkbox
                    readOnly
                    // eslint-disable-next-line react/prop-types
                    checked={viewQusOptions.answers.indexOf('4') >= 0}
                />
                <h6 className=' mt-2' style={{ marginLeft: "7px", color: `${viewQusOptions.answers.indexOf('4') >= 0 ? 'green' : '#212B36'}` }} >{viewQusOptions.option_4}</h6>
            </div>) : ""}
    </ModalBody>
)

export default QueAnsReadonly;