import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditFoodCategory = () => {
    const { id } = useParams()
    const state = useLocation();
    const navigate = useNavigate();
    const [updatedFoodCategory, setUpdatedFoodCategory] = useState({
        name: "", file: ""
    });

    const handleInputs = (e) => {
        if (e.target.name === "file") {
            setUpdatedFoodCategory({ ...updatedFoodCategory, [e.target.name]: e.target.files[0] });
        } else {
            setUpdatedFoodCategory({ ...updatedFoodCategory, [e.target.name]: e.target.value });
        }
    }

    // UPDATE image api
    const formData = new FormData();
    //    formData.append('image', updatedFoodCategory.file);
    //    formData.append('path', 'food-categories');

    const PUT_IMAGE = `/v1/upload-image`;
    const PUT_URL = `/v1/food-categories/${id}`;
    const putFoodCategory = async () => {
        try {
            const response1 = await api.post(PUT_IMAGE, formData,
            );
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.put(PUT_URL,
                    {
                        image_url: 'response1.data.image_url',
                        name: updatedFoodCategory.name,
                    },
                )
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/foodcategory', {
                replace: true,
                state: {
                    stage: state.state.stage,
                },
            })
        } catch (err) {
            //  console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        putFoodCategory();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadFoodCategory();
    }, []);

    const loadFoodCategory = async () => {
        const URL = `/v1/food-categories/${id}`
        const result = await api.get(URL);
        setUpdatedFoodCategory(result.data)
    }

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Food Category Name
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    {/* <Box
                        component="img"
                        sx={{ height: 150, width: 200, borderRadius: '0', }}alt=""
                        src={updatedFoodCategory.image_url}
                    />
                    <div>
                        <TextField
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleInputs}/>
                    </div> */}
                    <div >
                        <TextField
                            label="Food Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedFoodCategory.name || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={!updatedFoodCategory.name}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditFoodCategory;