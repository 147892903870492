import React from 'react'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

const PhoneSelect = () => {
    return (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel>Phone</InputLabel>
            <Select
                id="demo-simple-select-helper"
                label="Phone"
            // onChange={handleChange}
            >
                <MenuItem >Android</MenuItem>
                <MenuItem >ios</MenuItem>
            </Select>
        </FormControl>
    )
}

export default PhoneSelect