import { Button, Container, TextField, Box, Checkbox, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditQuizTopic = () => {
  const { categoryId, id } = useParams();
  const navigate = useNavigate();
  const [viewQuesions, setViewQuesions] = useState({});
  const [editQuizTopic, setEditQuizTopic] = useState({
    question: '',
    option_1: {
      isChecked: false,
      content: '',
    },
    option_2: {
      isChecked: false,
      content: '',
    },
    option_3: {
      isChecked: false,
      content: '',
    },
    option_4: {
      isChecked: false,
      content: '',
    },
    quizctgyID: '',
    answers: [],
  });

  const handleInputs = (event, index) => {
    const { name, value } = event.target;
    const optString = `option_${(index + 1).toString()}`;
    setEditQuizTopic(prev => ({
      ...prev,
      [name]: {
        isChecked: prev[optString].isChecked,
        content: value,
      },
    }));
  };

  const handleOnChecked = (e, index) => {
    const { name } = e.target;
    const optString = `option_${(index + 1).toString()}`;
    setEditQuizTopic({
      ...editQuizTopic,
      [name]: {
        ...editQuizTopic[optString],
        isChecked: !editQuizTopic[optString].isChecked,
      },
    });
  };

  // update Quiz api call
  const PUT_URL = `/v1/quiz-questions/${id}`;
  const answersStr = [
    editQuizTopic.option_1.isChecked,
    editQuizTopic.option_2.isChecked,
    editQuizTopic.option_3.isChecked,
    editQuizTopic.option_4.isChecked,
  ]
    .map((item, idx) => {
      if (item) {
        return (idx + 1).toString();
      }
      return 0;
    })
    .filter(Boolean)
    .join(',');
  // console.log(answersStr)
  const putQuizTopic = async () => {
    try {
      const response = await api.put(
        PUT_URL,
        {
          question: editQuizTopic.question,
          option_1: editQuizTopic.option_1.content,
          option_2: editQuizTopic.option_2.content,
          option_3: editQuizTopic.option_3.content,
          option_4: editQuizTopic.option_4.content,
          answers: answersStr,
          quiz_category_id: categoryId,
        }
      );
      if (response.status === 200) {
        navigate(`/dashboard/viewquiztopic/${categoryId}`, { replace: true });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleSubmit = () => {
    putQuizTopic();
  };

  // get priveous data on edit page
  useEffect(() => {
    prevQuizTopic();
    loadQuizTopic();
  }, []);

  const prevQuizTopic = async () => {
    const URL = `/v1/quiz-questions/${id}`;
    const result = await api.get(URL);
    const answers = result.data.answers.split(',').filter(Number);
    const response = { ...result.data, answers };
    setEditQuizTopic({
      ...response,
      option_1: {
        isChecked: answers.includes('1') ?? false,
        content: result.data.option_1,
      },
      option_2: {
        isChecked: answers.includes('2') ?? false,
        content: result.data.option_2,
      },
      option_3: {
        isChecked: answers.includes('3') ?? false,
        content: result.data.option_3,
      },
      option_4: {
        isChecked: answers.includes('4') ?? false,
        content: result.data.option_4,
      },
    });
  };

  // for category name on new page 

  const loadQuizTopic = async () => {
    const URL = `/v1/quiz-categories/${categoryId}`
    const result = await api.get(URL);
    setViewQuesions(result.data);
  }

  const inputMissing = () => !(editQuizTopic.question && editQuizTopic.answers);

  const optArrayName = [editQuizTopic.option_1, editQuizTopic.option_2, editQuizTopic.option_3, editQuizTopic.option_4];
  return (
    <>
      <Container sx={{ display: 'table-caption' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Quiz Topic : ({viewQuesions.name})
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="Question"
              name="question"
              id="question"
              multiline='true'
              onChange={(e) => setEditQuizTopic((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
              value={editQuizTopic.question || ''}
            />
          </div>

          {optArrayName.map((optionName, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={!!optionName.isChecked}
                name={`option_${index + 1}`}
                onChange={(e) => handleOnChecked(e, index)}
                inputProps={{ 'aria-label': 'controlled' }}
              />

              <TextField
                label={`Option ${index + 1}`}
                name={`option_${index + 1}`}
                id={`option_${index + 1}`}
                style={{ width: 300 }}
                onChange={(e) => handleInputs(e, index)}
                value={optionName.content || ""}
              />
            </div>
          ))}
          <Button variant="contained" style={{ marginLeft: '10px' }} disabled={inputMissing()} onClick={handleSubmit}>
            Update
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default EditQuizTopic;
