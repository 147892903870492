import { Button, Container, Box, Typography, Stack } from '@mui/material';
import JoditEditor from 'jodit-react';
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewConstantEditor = () => {
    const { id } = useParams()
    const editor = useRef(null);
    const navigate = useNavigate();
    const [viewConstTypeName, setViewConstTypeName] = useState({});
    const [newConstantEditor, setNewConstantEditor] = useState({
        content: ""
    });

    const contentFieldChanged = (data) => {
        setNewConstantEditor({ ...newConstantEditor, 'content': data })
    }

    // post new api call
    const POST_URL = `/v1/add-constant`;
    const postConstantValue = async () => {
        try {
            const response = await api.post(POST_URL, {
                value: newConstantEditor.content,
                type: id,
            })
            if (response.status === 200) {
                navigate('/dashboard/constanttypes', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postConstantValue();
    }

    // for name display
    useEffect(() => {
        loadConstantType();
    }, []);

    const loadConstantType = async () => {
        const URL = `/v1/constantTypes/${id}`
        const result = await api.get(URL)
        setViewConstTypeName(result.data)
    }

    const inputMissing = () => !(newConstantEditor.content);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Constant Value : ( {viewConstTypeName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{ maxWidth: '80%' }}>
                        <JoditEditor
                            ref={editor}
                            value={newConstantEditor.content || ""}
                            onChange={(newContent) => contentFieldChanged(newContent)}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "8px", marginTop: '5px' }}
                        disabled={inputMissing()}
                        onClick={handleSubmit}
                    >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewConstantEditor;