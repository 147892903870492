import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditVaccination = () => {
    const { countryid, id } = useParams()
    const navigate = useNavigate();
    const state = useLocation();
    const [countryIdByName, setCountryIdByName] = useState([]);
    const [editVaccination, setEditVaccination] = useState({
        name: "", description: ""
    });

    const handleInputs = (e) => {
        setEditVaccination({ ...editVaccination, [e.target.name]: e.target.value })
    }

    // update api call
    const PUT_URL = `/v1/vaccinationTime/${id}`;
    const putVaccination = async () => {
        try {
            const response = await api.put(PUT_URL, {
                name: editVaccination.name,
                description: editVaccination.description,
                country_id: countryid,
            })
            if (response.status === 200) {
                navigate('/dashboard/vaccination', {
                    replace: true,
                    state: {
                        country: state.state.country,
                    },
                })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putVaccination();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadVaccination();
        getcountryIdBy();
    }, []);

    const loadVaccination = async () => {
        const GET_URL = `/v1/vaccinationTime/${id}`
        try {
            const response = await api.get(GET_URL);
            setEditVaccination(response.data);
        } catch (err) {
            // console.log(err)
        }
    }

    // get api call country name
    const getcountryIdBy = async () => {
        const GET_URL = `/v1/countries/${countryid}`
        try {
            const response = await api.get(GET_URL);
            setCountryIdByName(response.data);
        } catch (err) {
            // console.log(err)
        }
    };
    const inputMissing = () => !(editVaccination.name && editVaccination.description);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Vaccination : ( {countryIdByName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={editVaccination.name || ""}
                        />
                    </div>
                    <div >
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={editVaccination.description || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}

export default EditVaccination;