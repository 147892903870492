import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Iconify from '../../components/iconify';

const AddNewUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // submit form to backend here
    console.log(userDetails);
  };

  const inputMissing = () => !(userDetails.name && userDetails.password && userDetails.email && userDetails.role);

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
          <Typography variant="h4" gutterBottom>
            Add New User
          </Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="Name"
              name="name"
              id="name"
              onChange={handleInputs}
              value={userDetails.name}
              type="text"
            />
          </div>

          <div>
            <TextField
              label="Email"
              name="email"
              id="email"
              onChange={handleInputs}
              value={userDetails.email}
              type="email"
            />
          </div>

          <div>
            <TextField
              name="password"
              label="Password"
              onChange={handleInputs}
              value={userDetails.password}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
              <InputLabel id="role-select">Role</InputLabel>
              <Select
                labelId="role-select"
                id="role-select"
                label="Role"
                name="role"
                sx={{ p: 1 }}
                value={userDetails.role}
                onChange={handleInputs}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button
            variant="contained"
            style={{ marginLeft: '10px', marginTop: '5px' }}
            disabled={inputMissing()}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default AddNewUser;
