import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {FaBars} from 'react-icons/fa' 
import { motion } from "framer-motion"
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import navConfig from './config';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [isOpen, setIsOpen] = useState(true);

    const toggle = ()=> setIsOpen(!isOpen);

  useEffect(() => {
    
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (

    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
      style={{ width: isOpen ? "260px" : "60px", transition:'all 1s' }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', alignItems:'center', justifyContent:'space-between' }} >
        <div style={{ display: isOpen ? "block" : "none" }}><Logo /></div>
      <div style={{ marginLeft: isOpen ? "70px" : "0px",transition:'all 1s' }}> <FaBars onClick={toggle}  /></div>
      </Box>

      <NavSection data={navConfig}  />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
      style={{ width: isOpen ? "300px" : "50px", transition:'all 1s'  }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            // sx: {
            //   width: NAV_WIDTH,
            //   bgcolor: 'background.default',
            //   borderRightStyle: 'dashed',
            // },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            // sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
