import React from 'react'

const ReportsPage = () => {
    return (
        <div>
            <h1>Reports</h1>
        </div>
    )
}

export default ReportsPage
