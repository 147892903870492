import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const EditMilestoneGroup = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [editMilestoneGroup, setEditMilestoneGroup] = useState({
        name: ""
    });

    const handleInputs = (e) => {
        setEditMilestoneGroup({ ...editMilestoneGroup, [e.target.name]: e.target.value })
    }

    // add new api
    const PUT_URL = `/v1/milestoneGroups/${id}`;
    const putEditMilestoneGroup = async () => {
        try {
            const response = await api.put(PUT_URL,
                {
                    name: editMilestoneGroup.name,
                })
            if (response.status === 200) {
                navigate('/dashboard/milestonegroup', { replace: true })
                setEditMilestoneGroup({ name: "" })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putEditMilestoneGroup();
    }

    // get priveous data on edit page
    useEffect(() => {
        loadMilestoneGroup();
    }, []);

    const loadMilestoneGroup = async () => {
        const URL = `/v1/milestoneGroups/${id}`
        const result = await api.get(URL);
        setEditMilestoneGroup(result.data);
    }

    const inputMissing = () => !(editMilestoneGroup.name);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Milestone Group
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div >
                        <TextField
                            label="Edit Milestone Group"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={editMilestoneGroup.name || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Update</Button>
                </Box>
            </Container>
        </>
    )
}
export default EditMilestoneGroup;