import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'name', label: 'Category Name', alignRight: false },
  { id: 'stage', label: 'Stage', alignRight: false },
  { id: '1' },
  { id: '2' },
  { id: '3' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CommunityCategory() {
  const state = useLocation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [communityCategory, setCommunityCategory] = useState([]);
  const [idToDelete, setIdToDelete] = useState({});
  const [selectedStageId, setSelectedStageId] = useState(state.state != null ? state.state.stage : '');
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState('');

  // filter stage


  useEffect(() => {
    getCommunityCategory();
    getCountriesDropdown();
  }, []);

  const getCommunityCategory = async () => {
    const GET_URL = `/v1/community-categories-filtered/null/null`;
    try {
      const response = await api.get(GET_URL);
      setCommunityCategory(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      // console.log(err)
    }
  };

  // to get dropdown of countries
  const getCountriesDropdown = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountries(response.data);
      console.log('Countries Data: ', response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error Showing Countries', err);

    }
  };

  // delete api call
  const handleDelete = async () => {
    const DELETE_URL = `/v1/community-categories/${idToDelete.id}`;
    try {
      const response = await api.delete(DELETE_URL);
      getCommunityCategory();
      toggle();
      const newRowCount = communityCategory.length - 1;
      const newPageCount = Math.ceil(newRowCount / rowsPerPage);
      if (page >= newPageCount) {
        setPage(newPageCount - 1);
      }
    } catch (err) {
      toggle();
      // console.log(err)
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(communityCategory.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };
  const handleFilter = async () => {
    const GET_URL = `/v1/community-categories-filtered/${selectedStageId || 'null'}/${selectedCountryId || 'null'}`;
    try {
      const response = await api.get(GET_URL);
      const sortedResponse = response.data.sort((a, b) => {
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);
        return dateB - dateA;
      });
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
      setCommunityCategory(sortedResponse);
    } catch (error) {
      console.log(error);
    }
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - communityCategory.length) : 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Community category
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="demo-select-small">Stage</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Stage"
              sx={{ p: 1 }}
              value={selectedStageId || ''}
              onChange={(event) => {
                setSelectedStageId(Number(event.target.value));
              }}
            >
              <MenuItem value={1}>Pre-pregnancy</MenuItem>
              <MenuItem value={2}>Pregnancy</MenuItem>
              <MenuItem value={3}>Parenting</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="country-select">Country</InputLabel>
            <Select
              labelId="country-select"
              id="country-select"
              label="Country"
              sx={{ p: 1 }}
              value={selectedCountryId}
              onChange={(event) => {
                setSelectedCountryId(Number(event.target.value));
                const selectedCountry = countries.find((country) => country.id === event.target.value);
                if (selectedCountry) {
                  console.log('Selected Country: ', selectedCountry);
                }
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}

            </Select>

          </FormControl>
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>

          <Link state={{ stage: selectedStageId }} to={`/dashboard/newcommunitycategory`}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
              Community Category
            </Button>
          </Link>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={communityCategory.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {communityCategory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currCommCategory, index) => (
                      <TableRow hover key={index} >
                        <TableCell align="left">{index + 1 + rowsPerPage * page}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={currCommCategory.name}
                              src={currCommCategory.image_url}
                              sx={{ ml: 2 }}
                              style={{ width: '80px', height: '50px', borderRadius: '0px' }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{currCommCategory.name}</TableCell>
                        <TableCell align="left">
                          {currCommCategory.stage === 1
                            ? 'Pre-pregnancy'
                            : currCommCategory.stage === 2
                              ? 'Pregnancy'
                              : 'Parenting'}
                        </TableCell>
                        <TableCell>
                          <Link
                            state={{ stage: selectedStageId }}
                            to={`/dashboard/editcommunitycategory/${currCommCategory.id}`}
                          >
                            <Button variant="contained" sx={{ mt: 1 }}>
                              Edit
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{ mt: 1 }}
                            onClick={() => {
                              setIdToDelete(currCommCategory);
                              toggle();
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={communityCategory && communityCategory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* modal for delete item */}
      <Modal isOpen={open} style={{ marginTop: '13rem' }}>
        <ModalHeader toggle={toggle}>Topic : {idToDelete.name}</ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            No
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Yes{' '}
          </Button>
        </ModalFooter>
      </Modal >
    </>
  );
}
