import { Button, Container, TextField, Box, Checkbox, Stack, Typography } from '@mui/material';
import { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewQuizTopic = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [viewQuesions, setViewQuesions] = useState({});
    const [newQuizTopic, setNewQuizTopic] = useState({
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        quizctgyID: ""
    });
    const [answers, setAnswers] = useState([]);

    const handleInputs = (e) => {
        setNewQuizTopic({ ...newQuizTopic, [e.target.name]: e.target.value })

        if (e.target.checked) {
            setAnswers([...answers, e.target.value]);
        } else {
            setAnswers(answers.filter(answer => answer !== e.target.value));
        }
    }

    const Str = answers.toString();
    const newStr = Str.split('[').join("").split(']').join("");

    // post new Quiz api call

    const POST_URL = `/v1/quiz-questions`;
    const postQuizTopic = async () => {
        try {
            const response = await api.post(POST_URL,
                {
                    question: newQuizTopic.question,
                    option_1: newQuizTopic.option1,
                    option_2: newQuizTopic.option2,
                    option_3: newQuizTopic.option3,
                    option_4: newQuizTopic.option4,
                    answers: newStr,
                    quiz_category_id: id,
                    active: true
                })
            if (response.status === 200) {
                navigate(`/dashboard/viewquiztopic/${id}`, { replace: true })
                setNewQuizTopic({
                    question: "",
                    option1: "",
                    option2: "",
                    option3: "",
                    option4: "",
                    answers: "",
                    quizctgyID: ""
                })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        postQuizTopic()
    }

    // for category name on new page 

    useEffect(() => {
        loadQuizTopic();
    }, []);

    const loadQuizTopic = async () => {
        const URL = `/v1/quiz-categories/${id}`
        const result = await api.get(URL);
        setViewQuesions(result.data)
    }

    const inputMissing = () => !(newQuizTopic.question);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Quiz Topic : ({viewQuesions.name})
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Question"
                            name="question"
                            id="question"
                            multiline='true'
                            onChange={handleInputs}
                            value={newQuizTopic.question} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            value={1}
                            name='1'
                            onChange={handleInputs}
                            disabled={newQuizTopic.option1 === "" && true}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <TextField
                            label="Option 1"
                            name="option1"
                            id="option1"
                            style={{ width: 300 }}
                            onChange={handleInputs}
                            value={newQuizTopic.option1}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            value={2}
                            name='2'
                            onChange={handleInputs}
                            disabled={newQuizTopic.option2 === "" && true}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <TextField
                            label="Option 2"
                            name="option2"
                            id="option2"
                            style={{ width: 300 }}
                            onChange={handleInputs}
                            value={newQuizTopic.option2}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            value={3}
                            name='3'
                            disabled={newQuizTopic.option3 === "" && true}
                            onChange={handleInputs}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <TextField
                            label="Option 3"
                            name="option3"
                            id="option3"
                            style={{ width: 300 }}
                            onChange={handleInputs}
                            value={newQuizTopic.option3}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            value={4}
                            name='4'
                            disabled={newQuizTopic.option4 === "" && true}
                            onChange={handleInputs}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <TextField
                            label="Option 4"
                            name="option4"
                            id="option4"
                            style={{ width: 300 }}
                            onChange={handleInputs}
                            value={newQuizTopic.option4}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "50px", marginTop: "7px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Save</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewQuizTopic;