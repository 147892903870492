import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import styled from 'styled-components';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  return (
    <Box {...other}>
      {/* <List disablePadding sx={{ p: 1 }}>
        {
          currentUser?.role === "user" ? data.filter((item) => item.title === "resources").map((item, index) => (
            <NavItem key={index} item={item} />
          )
          ) : data.map((item, index) => <NavItem key={index} item={item} />)
        }
      </List> */}

      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

const DropdownLink = styled(Link)`
background: #fff;
height:50px;
padding-left: 3rem;
display:flex;
align-items:center;
text-decoration: none;
color: gray;
font-size: 15px;

&:hover{
  background: lightgray;
  color: #000;
  cursor: pointer;
}
`

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const [subnav, setSubNav] = useState(false);
  const showSubNav = () => setSubNav(!subnav);

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={title} onClick={item.subNav && showSubNav }  />
        
  
        {item.subNav && (
          <StyledNavItemIcon onClick={showSubNav}>
            {subnav ? item.iconOpened : item.iconClosed}
          </StyledNavItemIcon>
        )}
        
      </StyledNavItem>
      <div>
        {
          subnav && item.subNav.map((item, index) => (
            <DropdownLink to={item.path} key={index}>{ }{item.title}</DropdownLink>
          )
          )
        }
      </div>
    </>);
}
