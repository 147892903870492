import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Stack, Button, Container, Typography } from '@mui/material';
import JoditEditor from 'jodit-react';
import Iconify from '../../../components/iconify/Iconify';

import api from '../../../Interceptors/Interceptor';


export default function ViewSingleValueConstant({ id }) {

    const [data, setData] = useState({});
    const location = useLocation();

    // get api call
    useEffect(() => {
        const getConstantType = async () => {
            const GET_URL = `/v1/constant-type/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data
            } catch (err) {
                // console.log(err)
            }
            return null;
        };

        const getConstantData = async () => {
            const GET_URL = `/v1/constant/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data;
            } catch (err) {
                // console.log(err)
            }
            return null;
        };
        getConstantType()
        .then((constantType)=> {
            getConstantData()
            .then((constantData)=> {
                const dataObj =  {
                    typeId: constantType.id,
                    typeName: constantType.name,
                    dataId: constantData[0].id,
                    value: constantData[0].value
                }
                setData(dataObj);
            })
        });
    }, []);


    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data.typeName}
                    </Typography>
                    <Link to={`${location.pathname}/edit`} >
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
                            Edit
                        </Button>
                    </Link>
                </Stack>
                <Card>
                    <div className="rich-text-viewer">
                        <JoditEditor
                            value={data.value}
                            config={{
                                readonly: true,
                                toolbar: false
                            }}
                        />
                    </div>
                </Card>
            </Container>
        </>
    );
}
