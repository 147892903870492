import { Button, Container, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../Firebase';

const NewUser = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    country: '',
  });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const hanldeAddUser = async (e) => {
    e.preventDefault();
    try {
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      await setDoc(doc(db, 'Users', res.user.uid), {
        ...data,
        timeStamp: serverTimestamp(),
      });
      navigate('/dashboard/user', { replace: true });
      // console.log(res)
    } catch (err) {
      // console.log(err.message);
    }
  };

  const inputMissing = () => !(data.name && data.email && data.password && data.country);

  return (
    <>
      <Container>
        <h1>New User</h1>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField label="Name" name="name" placeholder="Name" onChange={handleInput} required error />
            <TextField
              label="Email"
              name="email"
              placeholder="abc@gmail.com"
              onChange={handleInput}
              required
              // helperText={!data.email ? "Email is required" : "abc@gmail.com"}
              error
            />
          </div>
          <div>
            <TextField label="Country" name="country" placeholder="Country" onChange={handleInput} required error />
            <TextField
              label="Password"
              name="password"
              placeholder="Minimum 6 Digit"
              onChange={handleInput}
              required
              error={data.password !== 6}
            />
          </div>
          <div>
            <TextField
              label="Phone Number"
              name="phone"
              type="number"
              placeholder="Minimum 10 digit"
              onChange={handleInput}
            />
          </div>
          <Button disabled={inputMissing()} variant="contained" style={{ marginLeft: '10px' }} onClick={hanldeAddUser}>
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default NewUser;
