import { Button, Container, TextField, Box, Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const EditResourcesCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const state = useLocation();


    const [file, setFile] = useState(null);
    const [imgThumbnail, setImgThumbnail] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [stage, setStage] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);



    useEffect(() => {
        // Fetch resource category from the API on component mount
        const fetchResourceCategory = async () => {
            const GET_URL = `/v1/resource-categories/${id}`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setImgThumbnail(response.data.image_url);
                setCategoryName(response.data.name);
                setStage(response.data.stage);
                setSelectedCountries(response.data.countries);
                setSelectedLanguage(response.data.language);
            } catch (error) {
                console.error('Error fetching countries', error);
            }
        };

        // Fetch countries list from the API on component mount
        const fetchCountries = async () => {
            const GET_URL = `/v1/get-all-countries`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries', error);
            }
        };

        // Fetch languages list from the API on component mount
        const fetchLanguages = async () => {
            const GET_URL = `/v1/get-all-languages`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setLanguages(response.data);
            } catch (error) {
                console.error('Error fetching languages', error);
            }
        };

        fetchCountries().then((val) => {
            fetchLanguages().then((val) => {
                fetchResourceCategory();
            });
        });
    }, []);


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'image/png') {
            setFile(selectedFile);
        } else {
            alert('Please select a PNG image.');
        }
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value.slice(0, 30));
    };

    const handleStageChange = (e) => {
        setStage(e.target.value);
    };

    const handleCountryChange = (e) => {
        const { value, checked } = e.target;

        const parsedValue = parseInt(value, 10);
        if (checked) {
            const prevCountries = [...selectedCountries];
            setSelectedCountries([...prevCountries, parsedValue]);
        } else {
            setSelectedCountries((prev) => prev.filter(country => country !== parsedValue))
        }
        console.log(selectedCountries)
    };

    const handleLanguageChange = (e) => {
        if(e.target.value){
            setSelectedLanguage(parseInt(e.target.value,10));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName || !stage || selectedCountries.length === 0) {
            alert('Please fill all fields.');
            return;
        }

        const formData = new FormData();
        if (file) formData.append('image', file);
        formData.append('path', 'resource-categories');

        try {
            const payload = {
                name: categoryName,
                stage,
                active: true
            };

            if (file) {
                const resImg = await api.post(`/v1/upload-image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (resImg.status !== 200) {
                    throw new Error("Failed to save image");
                }
                payload.image_url = resImg.data.image_url;
            }

            const resCommCat = await api.put(`/v1/resource-categories/${id}`, payload);
            if (resCommCat.status !== 200) {
                throw new Error("Failed to save data");
            }

            const resCommCatCountries = await api.post(`/v1/resource-category-countries`,
                {
                    categoryId: id,
                    countries: JSON.stringify(selectedCountries)
                });
            if (resCommCatCountries.status !== 200) {
                throw new Error("Failed to save countires");
            }
            
            const resCatLanguage = await api.post(`/v1/resource-category-language`,
                {
                    categoryId: id,
                    language: selectedLanguage
                });
            if (resCatLanguage.status !== 200) {
                throw new Error("Failed to save language");
            }

            alert('Category updated successfully!');

            navigate('/dashboard/resourcescategory', {
                replace: true
            });

            setFile(null);
            setCategoryName("");
            setStage("");
            setCountries([]);
            setSelectedCountries([]);
            setLanguages([]);
            setSelectedLanguage(null);
        } catch (error) {
            console.error('Error saving category', error);
            alert('Failed to save category.');
        }
    };


    return (
        <Container fluid="true">
            <Typography variant="h4" gutterBottom>
                Edit Resource Category
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                        <input
                            type="file"
                            id="fileInput"
                            accept=".png"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <Button variant="outlined" component="label" htmlFor="fileInput">
                            Upload Image
                        </Button>
                        <Typography variant="caption" color="textSecondary">
                            Dimensions (60px x 60px) in PNG format
                        </Typography>
                        {imgThumbnail && (
                            <div style={{ marginTop: '16px' }}>
                                <img
                                    src={imgThumbnail}
                                    alt="Selected"
                                    style={{ width: '60px', maxHeight: '60px', objectFit: 'cover' }}
                                />
                            </div>
                        )}
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Category Name"
                        id="categoryNameInput"
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        inputProps={{ maxLength: 30 }}
                        sx={{ m: 1, minWidth: 440 }} size="small"
                    />
                </Box>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
                        <InputLabel htmlFor="stageSelect">Stage</InputLabel>
                        <Select
                            id="stageSelect"
                            value={stage}
                            onChange={handleStageChange}
                            label="Stage"
                        >
                            <MenuItem value=""><em>Select a stage</em></MenuItem>
                            <MenuItem value="1">Pre-pregnancy</MenuItem>
                            <MenuItem value="2">Pregnancy</MenuItem>
                            <MenuItem value="3">Parenting</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <FormControl component="fieldset" sx={{ m: 1, minWidth: 440 }} size="small" >
                        <Typography variant="h6" gutterBottom>
                            Countries
                        </Typography>
                        <FormGroup>
                            {
                                countries && countries.length > 0 ? (
                                    countries.map((country, index) => (
                                        <FormControlLabel
                                            key={`country-list-item-${index}`}
                                            control={
                                                <Checkbox
                                                    id={`countryCheckbox-${country.id}`}
                                                    checked={selectedCountries.includes(country.id)}
                                                    value={country.id}
                                                    onChange={handleCountryChange}
                                                />
                                            }
                                            label={country.name}
                                        />
                                    ))) : (<Container />)
                            }
                        </FormGroup>
                    </FormControl>
                </Box>
                
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
                        <InputLabel htmlFor="languageSelect">Language</InputLabel>
                        <Select
                            id="languageSelect"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            label="Language"
                        >
                            {
                                languages && languages.length > 0 ?
                                    (
                                        languages.map(country => (
                                            <MenuItem
                                                value={country.id}
                                            ><em>{country.name}</em></MenuItem>
                                        ))
                                    ) :
                                    (
                                        <Container/>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </form>
        </Container>
    );


};

export default EditResourcesCategory;
