import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewVaccine = () => {
    const { countryid, id } = useParams()
    const navigate = useNavigate();
    const [viewVaccineName, setViewVaccineName] = useState({});
    const [countryName, setCountryName] = useState([]);
    const [newVaccine, setNewVaccine] = useState({
        description: "", name: ""
    });

    const handleInputs = (e) => {
        setNewVaccine({ ...newVaccine, [e.target.name]: e.target.value })
    }

    // post new api call
    const POST_URL = `/v1/add-Vaccine`;
    const postVaccine = async () => {
        try {
            const response = await api.post(POST_URL, {
                name: newVaccine.name,
                vaccination_time_id: id,
                description: newVaccine.description,
            })
            if (response.status === 200) {
                navigate(`/dashboard/viewvaccine/${countryid}/${id}`, { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postVaccine();
    }

    // for vaccine name display
    useEffect(() => {
        loadVaccineGP();
        getcountryName();
    }, []);

    const loadVaccineGP = async () => {
        const URL = `/v1/vaccinationTime/${id}`
        const result = await api.get(URL);
        setViewVaccineName(result.data);
    }

    // get api call country name
    const getcountryName = async () => {
        const GET_URL = `/v1/countries/${countryid}`
        try {
            const response = await api.get(GET_URL)
            setCountryName(response.data);
        } catch (err) {
            // console.log(err)
        }
    };

    const inputMissing = () => !(newVaccine.description && newVaccine.name);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Vaccine : ( {countryName.name}/{viewVaccineName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newVaccine.name || ""}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Description"
                            name="description"
                            id="description"
                            onChange={handleInputs}
                            value={newVaccine.description || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewVaccine;