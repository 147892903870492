import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Select from '@mui/material/Select';
import Iconify from '../../../components/iconify/Iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import api from '../../../Interceptors/Interceptor';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'name', label: 'Category Name', alignRight: false },
  { id: 'stage', label: 'Stage', alignRight: false },
  { id: '1' },
  { id: '2' },
  { id: '3' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ResourceCategory() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [resourcesCategory, setResourcesCategory] = useState([]);
  const [idToDelete, setIdToDelete] = useState({});
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState('null');
  const [selectedLanguageId, setSelectedLanguageId] = useState('null');

  const toggle = () => setOpen(!open);

  useEffect(() => {
    getResourceCategory();
    getCountriesDropdown();
    getLanguagesDropdown();
  }, []);

  const getResourceCategory = async () => {
    const GET_URL = `/v1/resource-categories-filtered/null/null`;
    try {
      const response = await api.get(GET_URL);
      setResourcesCategory(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // to get countries for dropdown
  const getCountriesDropdown = async () => {
    const GET_URL = `/v1/get-all-countries`;
    try {
      const response = await api.get(GET_URL);
      setCountries(response.data);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error Showing Countries', err);
    }
  };
  // to get the languages for dropdown
  const getLanguagesDropdown = async () => {
    const GET_URL = `/v1/get-all-languages`;
    try {
      const response = await api.get(GET_URL);
      setLanguages(response.data);
      if (!response === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (err) {
      console.log('Error Showing Languages', err);
    }
  };

  // delete api call
  const handleDelete = async () => {
    const DELETE_URL = `/v1/resource-categories/${idToDelete.id}`;
    try {
      const response = await api.delete(DELETE_URL);
      getResourceCategory();
      toggle();
      const newRowCount = resourcesCategory.length - 1;
      const newPageCount = Math.ceil(newRowCount / rowsPerPage);
      if (page >= newPageCount) {
        setPage(newPageCount - 1);
      }
    } catch (err) {
      toggle();
      console.log(err);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPageCount = Math.ceil(resourcesCategory.length / newRowsPerPage);
    const newPage = Math.min(page, newPageCount - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleFilter = async() =>{
    const GET_URL = `/v1/resource-categories-filtered/${selectedCountryId || 'null'}/${selectedLanguageId || 'null'}`;
    try {
      const response = await api.get(GET_URL);
      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
      setResourcesCategory(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - resourcesCategory.length) : 0;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Resource category
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="country-select">Country</InputLabel>
            <Select
              labelId="country-select"
              id="country-select"
              label="Country"
              sx={{ p: 1 }}
              value={selectedCountryId}
              onChange={(event) => {
                setSelectedCountryId(Number(event.target.value));
                const selectedCountry = countries.find((country) => country.id === event.target.value);
                if (selectedCountry) {
                  // console.log("Selected Country: ", selectedCountry);
                }
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
            <InputLabel id="language-select">Language</InputLabel>
            <Select
              labelId="language-select"
              id="language-select"
              label="Language"
              sx={{ p: 1 }}
              value={selectedLanguageId}
              onChange={(event) => {
                setSelectedLanguageId(Number(event.target.value));
                const selectedLanguage = languages.find((language) => language.id === event.target.value);
                if (selectedLanguage) {
                  // console.log("Selected Language", selectedLanguage.name);
                }
              }}
            >
              {languages.map((language) => (
                <MenuItem value={language.id} key={language.id}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleFilter}>
            Filter
          </Button>
          <Link to={`/dashboard/newresourcescategory`}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} >
            Resource Category
            </Button>
          </Link>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={countries.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {resourcesCategory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((currResourceCategory, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="left">{index + 1 + rowsPerPage * page}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={currResourceCategory.name}
                              src={currResourceCategory.image_url}
                              sx={{ ml: 2 }}
                              style={{ width: '80px', height: '50px', borderRadius: '0px' }}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{currResourceCategory.name}</TableCell>
                        <TableCell align="left">
                          {currResourceCategory.stage === 1
                            ? 'Pre-pregnancy'
                            : currResourceCategory.stage === 2
                            ? 'Pregnancy'
                            : 'Parenting'}
                        </TableCell>
                        <TableCell>
                          <Link to={`/dashboard/editresourcescategory/${currResourceCategory.id}`}>
                            <Button variant="contained" sx={{ mt: 1 }}>
                              Edit
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell>
                            <Button variant='contained' sx={{ mt: 1 }} onClick={() => { setIdToDelete(currResourceCategory); toggle(); }}
                            >Delete</Button>
                          </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[0, 25]}
            component="div"
            count={resourcesCategory && resourcesCategory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* modal for delete item */}
      <Modal isOpen={open} style={{ marginTop: '13rem' }}>
        <ModalHeader toggle={toggle}>Topic : {idToDelete.name}</ModalHeader>
        <ModalBody>Sure, You want to Delete? </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            No
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Yes{' '}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
