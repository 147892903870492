import { Button, Container, TextField, Box, Stack, Typography, ListItem, ListItemText } from '@mui/material';
import { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import api from '../../../Interceptors/Interceptor';

const NewDeliveryValue = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [checkListOption, setCheckListOption] = useState('')
    const [itemArray, setItemArray] = useState([]);
    const [viewDeliveryListName, setViewDeliveryListName] = useState({});

    const addItem = (e) => {
        if (!checkListOption) {
            // some code here
        } else {
            setItemArray([...itemArray, checkListOption]);
            setCheckListOption('')
        }
    }

    // add new api
    const POST_URL = `/v1/add-deliveryChecklist`;
    const postNewDeliveryValue = async () => {
        try {
            const response = await api.post(POST_URL,
                {
                    name: itemArray,
                    delivery_checklist_group_id: id
                })
            if (response.status === 200) {
                navigate('/dashboard/deliverychecklist', { replace: true })
            }
        } catch (err) {
            // console.log(err)
        }
    };

    const removeItem = (name) => {
        const selectedItems = itemArray.filter((list) => list !== name);
        setItemArray(selectedItems);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewDeliveryValue();
    }

    // for name show
    useEffect(() => {
        loadDeliveryValue();
    }, []);

    const loadDeliveryValue = async () => {
        const URL = `/v1/deliveryChecklistGroups/${id}`
        const result = await api.get(URL)
        setViewDeliveryListName(result.data)
    }

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Delivery Value : ( {viewDeliveryListName.name} )
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            label="Checklist Option"
                            name="checklistoption"
                            id="checklistoption"
                            value={checkListOption || ""}
                            onChange={(e) => setCheckListOption(e.target.value)}
                        />
                        <AddCircleIcon color='primary' onClick={addItem} style={{ marginTop: "24px" }} />
                    </div>
                    <Typography variant="h5" gutterBottom style={{ marginLeft: "8px", marginTop: '5px' }}>
                        Check list Options
                    </Typography>
                    <div >
                        {itemArray.map((item, index) => {
                            return (
                                <ListItem key={index} style={{ width: '31rem' }}>
                                    <ListItemText style={{ width: '30rem' }} >{item}</ListItemText>
                                    <CancelIcon color='error' onClick={() => removeItem(item)} />
                                </ListItem>
                            )
                        })}
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px", marginTop: "7px" }}
                        disabled={!itemArray[0]}
                        onClick={handleSubmit} >Save</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewDeliveryValue;