import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import api from '../../../Interceptors/Interceptor';

const NewFoodCategory = () => {
    const state = useLocation();
    const navigate = useNavigate();
    const [file, setFile] = useState("");
    const [newFoodCategory, setNewFoodCategory] = useState({
        name: ""
    });

    const handleInputs = (e) => {
        setNewFoodCategory({ ...newFoodCategory, [e.target.name]: e.target.value })
    }

    // post image api
    const formData = new FormData();
    // formData.append('image', file);
    // formData.append('path', 'food-categories');

    const POST_IMAGE = `/v1/upload-image`;
    const POST_URL = `/v1/food-categories`;

    const fetchData = async () => {
        try {
            const response1 = await api.post(POST_IMAGE, formData,
            );
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.post(POST_URL,
                    {
                        image_url: 'response1.data.image_url',
                        name: newFoodCategory.name,
                        active: true
                    },
                )
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/foodcategory', {
                replace: true,
                state: {
                    stage: state.state.stage,
                },
            })
        } catch (err) {
            //  console.log(err)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchData();
        setNewFoodCategory({ name: "" });
        setFile("");
    }

    const inputMissing = () => !(newFoodCategory.name)
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Food Category
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    {/* <div>
                        <TextField
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => setFile(e.target.files[0])} />
                    </div> */}
                    <div >
                        <TextField
                            label="Food Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newFoodCategory.name || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
}

export default NewFoodCategory;