import { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Stack, Box, Button, Container, Typography } from '@mui/material';
import JoditEditor from 'jodit-react';

import api from '../../../Interceptors/Interceptor';


export default function EditSingleValueConstant({ id }) {
    const navigate = useNavigate();
    const location = useLocation();
    const editor = useRef(null);
    const [data, setData] = useState({});

    // get api call
    useEffect(() => {
        const getConstantType = async () => {
            const GET_URL = `/v1/constant-type/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data
            } catch (err) {
                // console.log(err)
            }
            return null;
        };

        const getConstantData = async () => {
            const GET_URL = `/v1/constant/${id}`
            try {
                const response = await api.get(GET_URL)
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                return response.data;
            } catch (err) {
                // console.log(err)
            }
            return null;
        };
        getConstantType()
            .then((constantType) => {
                getConstantData()
                    .then((constantData) => {
                        const dataObj = {
                            typeId: constantType.id,
                            typeName: constantType.name,
                            dataId: constantData[0].id,
                            value: constantData[0].value
                        }
                        setData(dataObj);
                    })
            });
    }, []);


    const contentFieldChanged = (val) => {
        data.value = val;
        setData(data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const payload = {
                value: data.value
            };

            const res = await api.put(`/v1/constant/${data.dataId}`, payload);
            if (res.status !== 200) {
                throw new Error("Failed to save data");
            }

            alert('Data updated successfully!');

            navigate(location.pathname.replace("/edit",""), {
                replace: true
            });
        } catch (error) {
            console.error('Error saving constant', error);
            alert('Failed to save data.');
        }
    };

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {data.typeName}
                    </Typography>
                </Stack>
                <Card>
                    <form onSubmit={handleSubmit}>
                        <Box mb={3}>
                            <div className="rich-text-viewer">
                                <JoditEditor
                                    ref={editor}
                                    value={data.value}
                                    onChange={(newContent) => contentFieldChanged(newContent)}
                                    config={{
                                        readonly: false,
                                        toolbar: true
                                    }}
                                />
                            </div>
                        </Box>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </form>
                </Card>
            </Container>
        </>
    );
}
