import { Button, Container, TextField, Box, Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const EditFoodItem = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [foodCategoryName, setFoodCategoryName] = useState([]);
    const [updatedFoodItem, setUpdatedFoodItem] = useState({
        file: "",
        name: "",
        baby: "",
        breastfeeding: "",
        afterBirth: "",
        pregnancy: "",
        content: "",
        food_category_id: "",
    });

    const handleInputs = (e) => {
        if (e.target.name === "file") {
            setUpdatedFoodItem({ ...updatedFoodItem, [e.target.name]: e.target.files[0] });
        } else {
            setUpdatedFoodItem({ ...updatedFoodItem, [e.target.name]: e.target.value });
        }
    };

    // Constants for statuses
    const STATUSES = {
        1: 'Unsafe',
        2: 'Tiny Portions Only',
        3: 'Caution',
        4: 'Safe'
    };

    const getBackgroundColor = (value) => {
        switch (value) {
            case '1':
                return "#E53935"; // Red for Unsafe
            case '2':
                return "#1E88E5"; // Blue for Caution
            case '3':
                return "#FB8C00"; // Orange for Tiny Portions Only
            case '4':
                return "#43A047"; // Green for Safe
            default:
                return "#FFFFFF"; // Default/Unknown
        }
    };

    // Update image and food item
    const formData = new FormData();
    formData.append('image', updatedFoodItem.file);
    formData.append('path', 'food-items');

    const PUT_IMAGE = `/v1/upload-image`;
    const PUT_URL = `/v1/food-items/${id}`;
    const putFoodItem = async () => {
        try {
            const response1 = await api.post(PUT_IMAGE, formData);
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.put(PUT_URL, {
                    image_url: response1.data.image_url,
                    name: updatedFoodItem.name,
                    baby: updatedFoodItem.baby,
                    breastfeeding: updatedFoodItem.breastfeeding,
                    afterBirth: updatedFoodItem.afterBirth,
                    pregnancy: updatedFoodItem.pregnancy,
                    content: updatedFoodItem.content,
                    food_category_id: updatedFoodItem.food_category_id,
                });
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/fooditems', { replace: true });
        } catch (err) {
            // Handle error
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        putFoodItem();
    };

    // Load previous data on edit page
    useEffect(() => {
        loadFoodItem();
        getFoodCategory();
    }, []);

    const loadFoodItem = async () => {
        const URL = `/v1/food-items/${id}`;
        const result = await api.get(URL);
        setUpdatedFoodItem(result.data);
    };

    const getFoodCategory = async () => {
        const GET_URL = `/v1/food-categories`;
        try {
            const response = await api.get(GET_URL);
            setFoodCategoryName(response.data);
        } catch (err) {
            // Handle error
        }
    };

    const inputMissing = () => !(updatedFoodItem.name && updatedFoodItem.pregnancy);

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit Food Item
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        component="img"
                        sx={{ height: 150, width: 200, borderRadius: '0' }}
                        alt=""
                        src={updatedFoodItem.image_url}
                    />
                    <div className='d-flex flex-column'>
                        <TextField
                            // label="Thumbnail"
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleInputs}
                        />
                        <Typography variant="caption" color="textSecondary" ml={1} mb={2}>
                          Dimensions (512px x 512px) in PNG format
                        </Typography>
                    </div>
                    <div>
                        <TextField
                            label="Food Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={updatedFoodItem.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>Baby</InputLabel>
                            <Select
                                id="baby"
                                label="Baby"
                                name='baby'
                                sx={{ p: 1 }}
                                value={updatedFoodItem.baby || ""}
                                onChange={handleInputs}
                            >
                                {Object.keys(STATUSES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                           
                                            <div style={{
                                                backgroundColor: getBackgroundColor(key),
                                                borderRadius: '50%',
                                                height: '10px',
                                                width: '10px',
                                                marginRight: '8px',
                                            }} /> {STATUSES[key]}
                                        </span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>Breast Feeding</InputLabel>
                            <Select
                                id="breastfeeding"
                                label="Breast Feeding"
                                name='breastfeeding'
                                sx={{ p: 1 }}
                                value={updatedFoodItem.breastfeeding || ""}
                                onChange={handleInputs}
                            >
                                {Object.keys(STATUSES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                         <div style={{
                                                backgroundColor: getBackgroundColor(key),
                                                borderRadius: '50%',
                                                height: '10px',
                                                width: '10px',
                                                marginRight: '8px',
                                            }} />     {STATUSES[key]}
                                          
                                        </span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>Pregnancy</InputLabel>
                            <Select
                                id="pregnancy"
                                label="Pregnancy"
                                name='pregnancy'
                                sx={{ p: 1 }}
                                value={updatedFoodItem.pregnancy || ""}
                                onChange={handleInputs}
                            >
                                {Object.keys(STATUSES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{
                                                backgroundColor: getBackgroundColor(key),
                                                borderRadius: '50%',
                                                height: '10px',
                                                width: '10px',
                                                marginRight: '8px',
                                            }} /> {STATUSES[key]}
                                           
                                        </span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>After Birth</InputLabel>
                            <Select
                                id="afterBirth"
                                label="After Birth"
                                name='afterBirth'
                                sx={{ p: 1 }}
                                value={updatedFoodItem.afterBirth || ""}
                                onChange={handleInputs}
                            >
                                {Object.keys(STATUSES).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                           <div style={{
                                                backgroundColor: getBackgroundColor(key),
                                                borderRadius: '50%',
                                                height: '10px',
                                                width: '10px',
                                                marginRight: '8px',
                                            }} /> {STATUSES[key]}
                                            
                                        </span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 210 }} size="small">
                            <InputLabel>Food Category</InputLabel>
                            <Select
                                id="food_category_id"
                                label="Food Category"
                                name='food_category_id'
                                sx={{ p: 1 }}
                                value={foodCategoryName.some(resource => resource.id === updatedFoodItem.food_category_id) ? updatedFoodItem.food_category_id : ''}
                                onChange={handleInputs}
                            >
                                {foodCategoryName.map((foodName, index) => (
                                    <MenuItem key={index} value={foodName.id}>{foodName.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            style={{ width: 800 }}
                            label="Description"
                            name="content"
                            id="content"
                            onChange={handleInputs}
                            value={updatedFoodItem.content || ""}
                        />
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit}>Update</Button>
                </Box>
            </Container>
        </>
    );
}

export default EditFoodItem;
