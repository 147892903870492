// import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import DashboardAppPage from './pages/DashboardAppPage';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/Registration/LoginPage';
import ForgetPasswordPage from './pages/Registration/ForgetPasswordPage';
import Page404 from './pages/Registration/Page404';

import UserPage from './pages/Users/UserPage';
import NewUser from './pages/Users/NewUser';
import EditUser from './pages/Users/EditUser';

import UsersRoles from './pages/Users/UsersRoles';
import ReportsPage from './pages/Report/ReportsPage';

import FoodCategory from './pages/Food/FoodCategory/FoodCategory';
import NewFoodCategory from './pages/Food/FoodCategory/NewFoodCategory';
import EditFoodCategory from './pages/Food/FoodCategory/EditFoodCategory';

import FoodItems from './pages/Food/FoodItems/FoodItems';
import NewFoodItem from './pages/Food/FoodItems/NewFoodItem';
import EditFoodItem from './pages/Food/FoodItems/EditFoodItem';

import ViewQuizTopic from './pages/Quiz/QuizTopic/ViewQuizTopic';
import NewQuizTopic from './pages/Quiz/QuizTopic/NewQuizTopic';
import EditQuizTopic from './pages/Quiz/QuizTopic/EditQuizTopic';

import QuizCategory from './pages/Quiz/QuizCategory/QuizCategory';
import NewQuizCategory from './pages/Quiz/QuizCategory/NewQuizCategory';
import EditQuizCategory from './pages/Quiz/QuizCategory/EditQuizCategory';

import CommunityTopic from './pages/Communities/CommunityTopic/CommunityTopic';
import NewCommunityTopic from './pages/Communities/CommunityTopic/NewCommunityTopic';
import EditCommunityTopic from './pages/Communities/CommunityTopic/EditCommunityTopic';

import CommunityCategory from './pages/Communities/CommunityCategory/CommunityCategory';
import NewCommunityCategory from './pages/Communities/CommunityCategory/NewCommunityCategory';
import EditCommunityCategory from './pages/Communities/CommunityCategory/EditCommunityCategory';

import ResourcesCategory from './pages/Resources/ResourceCategory/ResourcesCategory';
import NewResourcesCategory from './pages/Resources/ResourceCategory/NewResourcesCategory';
import EditResourcesCategory from './pages/Resources/ResourceCategory/EditResourcesCategory';

import ResourcesMedia from './pages/Resources/ResourceMedia/ResourcesMedia';
import NewResourceMedia from './pages/Resources/ResourceMedia/NewResourceMedia';
import EditResourceMedia from './pages/Resources/ResourceMedia/EditResourceMedia';

import ResourcesArticles from './pages/Resources/ResourceArticles/ResourcesArticles';
import NewResourcesArticles from './pages/Resources/ResourceArticles/NewResourcesArticles';
import EditResourcesArticles from './pages/Resources/ResourceArticles/EditResourcesArticles';
import PreviewResourcesArticles from './pages/Resources/ResourceArticles/PreviewResourcesArticles';

import Countries from './pages/Countries/Countries';
import NewCountries from './pages/Countries/NewCountries';
import EditCountries from './pages/Countries/EditCountries';

import Languages from './pages/Languages/Languages';
import NewLanguages from './pages/Languages/NewLanguages';
import EditLanguages from './pages/Languages/EditLanguages';

import ConstantTypes from './pages/Constants/ConstantsTypes/ConstantTypes';
import NewConstantsTypes from './pages/Constants/ConstantsTypes/NewConstantsTypes';
import EditConstantsTypes from './pages/Constants/ConstantsTypes/EditConstantsTypes';
import ViewConstantsValue from './pages/Constants/ConstantValue/ViewConstantsValue';
import NewConstantsValue from './pages/Constants/ConstantValue/NewConstantsValue';
import EditConstantsValue from './pages/Constants/ConstantValue/EditConstantsValue';

import NewConstantEditor from './pages/Constants/ConstantValue/NewConstantEditor';
import EditConstantEditor from './pages/Constants/ConstantValue/EditConstantEditor';

import MilestoneGroup from './pages/MileStones/MilestonesGroup/MilestoneGroup';
import NewMilestoneGroup from './pages/MileStones/MilestonesGroup/NewMilestoneGroup';
import EditMilestoneGroup from './pages/MileStones/MilestonesGroup/EditMilestoneGroup';

import ViewMilestone from './pages/MileStones/ViewMilestone/ViewMilestone';
import NewMilestone from './pages/MileStones/ViewMilestone/NewMilestone';
import EditMilestone from './pages/MileStones/ViewMilestone/EditMilestone';

import DeliveryChecklist from './pages/Delivery Checklist Groups/DeliveryChecklistGroup/DeliveryChecklist';
import NewDeliveryChecklist from './pages/Delivery Checklist Groups/DeliveryChecklistGroup/NewDeliveryChecklist';
import EditDeliveryChecklist from './pages/Delivery Checklist Groups/DeliveryChecklistGroup/EditDeliveryChecklist';
import ViewDeliveryValue from './pages/Delivery Checklist Groups/DeliveryChecklist/ViewDeliveryValue';
import NewDeliveryValue from './pages/Delivery Checklist Groups/DeliveryChecklist/NewDeliveryValue';
import EditDeliveryValue from './pages/Delivery Checklist Groups/DeliveryChecklist/EditDeliveryValue';
import Vaccination from './pages/Vaccine/Vaccination/Vaccination';
import NewVaccination from './pages/Vaccine/Vaccination/NewVaccination';
import EditVaccination from './pages/Vaccine/Vaccination/EditVaccination';
import ViewVaccine from './pages/Vaccine/Vaccine/ViewVaccine';
import NewVaccine from './pages/Vaccine/Vaccine/NewVaccine';
import EditVaccine from './pages/Vaccine/Vaccine/EditVaccine';
import CommentsPage from './pages/Communities/CommunityTopic/CommentsPage';

import Notification from './pages/Notification/Notification';
import NewNotification from './pages/Notification/NewNotification';
import ManageUser from './pages/Manage Users/ManageUser';
import AddNewUser from './pages/Manage Users/AddNewUser';
import ViewSingleValueConstant from './pages/Constants/ConstantValue/ViewSingleValueConstant';
import EditSingleValueConstant from './pages/Constants/ConstantValue/EditSingleValueConstant';
import ViewMultiValueConstant from './pages/Constants/ConstantValue/ViewMultiValueConstant';

export default function Router() {
  const currentUser = JSON.parse(localStorage.getItem('userDetail'));

  const RequireAuth = ({ children }) => currentUser !== null ? children : <Navigate to='/login' />;

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/resourcescategory" />, index: true },
        { path: 'app', element: <RequireAuth> <DashboardAppPage /> </RequireAuth> },

        // user
        { path: 'user', element: <RequireAuth> <UserPage /> </RequireAuth> },
        { path: 'role', element: <RequireAuth> <UsersRoles /> </RequireAuth> },
        { path: 'newuser', element: <RequireAuth> <NewUser /> </RequireAuth> },
        { path: 'edituser/:id', element: <RequireAuth> <EditUser /> </RequireAuth> },

        // food
        { path: 'foodcategory', element: <RequireAuth> <FoodCategory /> </RequireAuth> },
        { path: 'newfoodcategory', element: <RequireAuth> <NewFoodCategory /> </RequireAuth> },
        { path: 'editfoodcategory/:id', element: <RequireAuth> <EditFoodCategory /> </RequireAuth> },
        { path: 'fooditems', element: <RequireAuth> <FoodItems /> </RequireAuth> },
        { path: 'newfooditem', element: <RequireAuth> <NewFoodItem /> </RequireAuth> },
        { path: 'editfooditem/:id', element: <RequireAuth> <EditFoodItem /> </RequireAuth> },

        // Resources
        { path: 'resourcesarticle', element: <RequireAuth> <ResourcesArticles /> </RequireAuth> },
        { path: 'newresourcesarticle', element: <RequireAuth> <NewResourcesArticles /> </RequireAuth> },
        { path: 'editresourcesarticle/:id', element: <RequireAuth> <EditResourcesArticles /> </RequireAuth> },
        { path: 'previewresourcesarticles/:id', element: <RequireAuth> <PreviewResourcesArticles /> </RequireAuth> },
        { path: 'resourcescategory', element: <RequireAuth> <ResourcesCategory /> </RequireAuth> },
        { path: 'newresourcescategory', element: <RequireAuth> <NewResourcesCategory /> </RequireAuth> },
        { path: 'editresourcescategory/:id', element: <RequireAuth> <EditResourcesCategory /> </RequireAuth> },
        { path: 'resourcesmedia/:stageid/:featured', element: <RequireAuth> <ResourcesMedia /> </RequireAuth> },
        { path: 'newresourcesmedia/:stageid/:featured', element: <RequireAuth> <NewResourceMedia /> </RequireAuth> },
        { path: 'resourcesmedia', element: <RequireAuth> <ResourcesMedia /> </RequireAuth> },
        { path: 'newresourcesmedia', element: <RequireAuth> <NewResourceMedia /> </RequireAuth> },
        { path: 'editresourcesmedia/:id', element: <RequireAuth> <EditResourceMedia /> </RequireAuth> },

        // community
        { path: 'communitycategory', element: <RequireAuth> <CommunityCategory /> </RequireAuth> },
        { path: 'newcommunitycategory', element: <RequireAuth> <NewCommunityCategory /> </RequireAuth> },
        { path: 'editcommunitycategory/:id', element: <RequireAuth> <EditCommunityCategory /> </RequireAuth> },
        { path: 'communitytopic', element: <RequireAuth> <CommunityTopic /> </RequireAuth> },
        { path: 'commentspage/:id', element: <RequireAuth> <CommentsPage /> </RequireAuth> },
        { path: 'newcommunitytopic', element: <RequireAuth> <NewCommunityTopic /> </RequireAuth> },
        { path: 'editcommunitytopic/:id', element: <RequireAuth> <EditCommunityTopic /> </RequireAuth> },

        // quiz
        { path: 'quiz', element: <RequireAuth> <QuizCategory /> </RequireAuth> },
        { path: 'newquiz', element: <RequireAuth> <NewQuizCategory /> </RequireAuth> },
        { path: 'editquiz/:id', element: <RequireAuth> <EditQuizCategory /> </RequireAuth> },
        { path: 'viewquiztopic/:id', element: <RequireAuth> <ViewQuizTopic /> </RequireAuth> },
        { path: 'newquiztopic/:id', element: <RequireAuth> <NewQuizTopic /> </RequireAuth> },
        { path: 'editquiztopic/:categoryId/:id', element: <RequireAuth> <EditQuizTopic /> </RequireAuth> },

        // notifications
        { path: 'notification', element: <RequireAuth> <Notification /> </RequireAuth> },
        { path: 'newnotification', element: <RequireAuth> <NewNotification /> </RequireAuth> },

        // report
        { path: 'report', element: <RequireAuth> <ReportsPage /> </RequireAuth> },

        // constants
        { path: 'constanttypes', element: <RequireAuth> <ConstantTypes /> </RequireAuth> },
        { path: 'newconstanttypes', element: <RequireAuth> <NewConstantsTypes /> </RequireAuth> },
        { path: 'editconstanttypes/:id', element: <RequireAuth> <EditConstantsTypes /> </RequireAuth> },
        { path: 'viewconstantsvalue/:id', element: <RequireAuth> <ViewConstantsValue /> </RequireAuth> },
        { path: 'newconstantvalue/:id', element: <RequireAuth> <NewConstantsValue /> </RequireAuth> },
        { path: 'editconstantvalue/:categoryId/:id', element: <RequireAuth> <EditConstantsValue /> </RequireAuth> },
        { path: 'newconstanteditor/:id', element: <RequireAuth> <NewConstantEditor /> </RequireAuth> },
        { path: 'editconstanteditor/:categoryId/:id', element: <RequireAuth> <EditConstantEditor /> </RequireAuth> },

        { path: 'privacy-policy', element: <RequireAuth> <ViewSingleValueConstant id="7" editPath='privacy-policy'/> </RequireAuth> },
        { path: 'privacy-policy/edit', element: <RequireAuth> <EditSingleValueConstant id="7"/> </RequireAuth> },
        { path: 'refund-policy', element: <RequireAuth> <ViewSingleValueConstant id="11" editPath='refund-policy' /> </RequireAuth> },
        { path: 'refund-policy/edit', element: <RequireAuth> <EditSingleValueConstant id="11" /> </RequireAuth> },
        { path: 'terms-of-use', element: <RequireAuth> <ViewSingleValueConstant id="9" editPath='terms-of-use' /> </RequireAuth> },
        { path: 'terms-of-use/edit', element: <RequireAuth> <EditSingleValueConstant id="9" /> </RequireAuth> },
        
        { path: 'conception-treatment', element: <RequireAuth> <ViewMultiValueConstant id="1" editPath='conception-treatment' /> </RequireAuth> },
        { path: 'exercise-types', element: <RequireAuth> <ViewMultiValueConstant id="2" editPath='exercise-types' /> </RequireAuth> },
        { path: 'moods', element: <RequireAuth> <ViewMultiValueConstant id="4" editPath='moods' /> </RequireAuth> },
        { path: 'period-cycle-length', element: <RequireAuth> <ViewMultiValueConstant id="5" editPath='period-cycle-length' /> </RequireAuth> },
        { path: 'period-duration', element: <RequireAuth> <ViewMultiValueConstant id="6" editPath='period-duration' /> </RequireAuth> },


        // DeliveryChecklist
        { path: 'deliverychecklist', element: <RequireAuth> <DeliveryChecklist /> </RequireAuth> },
        { path: 'newdeliverychecklist', element: <RequireAuth> <NewDeliveryChecklist /> </RequireAuth> },
        { path: 'editdeliverychecklist/:id', element: <RequireAuth> <EditDeliveryChecklist /> </RequireAuth> },
        { path: 'viewdeliveryvalue/:id', element: <RequireAuth> <ViewDeliveryValue /> </RequireAuth> },
        { path: 'newdeliveryvalue/:id', element: <RequireAuth> <NewDeliveryValue /> </RequireAuth> },
        { path: 'editdeliveryvalue/:categoryId/:id', element: <RequireAuth> <EditDeliveryValue /> </RequireAuth> },

        // milestone
        { path: 'milestonegroup', element: <RequireAuth> <MilestoneGroup /> </RequireAuth> },
        { path: 'newmilestonegroup', element: <RequireAuth> <NewMilestoneGroup /> </RequireAuth> },
        { path: 'editmilestonegroup/:id', element: <RequireAuth> <EditMilestoneGroup /> </RequireAuth> },
        { path: 'viewmilestone/:id', element: <RequireAuth> <ViewMilestone /> </RequireAuth> },
        { path: 'newmilestone/:id', element: <RequireAuth> <NewMilestone /> </RequireAuth> },
        { path: 'editmilestone/:categoryId/:id', element: <RequireAuth> <EditMilestone /> </RequireAuth> },

        // Vaccination
        { path: 'vaccination', element: <RequireAuth> <Vaccination /> </RequireAuth> },
        { path: 'newvaccination/:id', element: <RequireAuth> <NewVaccination /> </RequireAuth> },
        { path: 'editvaccination/:countryid/:id', element: <RequireAuth> <EditVaccination /> </RequireAuth> },
        { path: 'viewvaccine/:countryid/:id', element: <RequireAuth> <ViewVaccine /> </RequireAuth> },
        { path: 'newvaccine/:countryid/:id', element: <RequireAuth> <NewVaccine /> </RequireAuth> },
        { path: 'editvaccine/:countryid/:dataid/:id', element: <RequireAuth> <EditVaccine /> </RequireAuth> },

        // Countries
        { path: 'countries', element: <RequireAuth> <Countries /> </RequireAuth> },
        { path: 'newcountries', element: <RequireAuth> <NewCountries /> </RequireAuth> },
        { path: 'editcountries/:id', element: <RequireAuth> <EditCountries /> </RequireAuth> },

        // Languages
        { path: 'languages', element: <RequireAuth> <Languages /> </RequireAuth> },
        { path: 'newlanguages', element: <RequireAuth> <NewLanguages /> </RequireAuth> },
        { path: 'editlanguages/:id', element: <RequireAuth> <EditLanguages /> </RequireAuth> },
        // Manage Users
        {path: 'manageuser', element: <RequireAuth><ManageUser/></RequireAuth>},
        {path: 'addnewuser', element: <RequireAuth><AddNewUser/></RequireAuth>}
      ],
    },
    {
      path: 'login', element: <LoginPage />,
    },
    {
      path: 'password', element: <ForgetPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/communitycategory" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*', element: <Navigate to="/404" replace />,
    },
  ]);

  return routes
}
