import { Button, Container, TextField, Box, Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from '../../../Interceptors/Interceptor';

const NewResourcesCategory = () => {
    const navigate = useNavigate();
    const state = useLocation();

    const [file, setFile] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [stage, setStage] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    useEffect(() => {
        // Fetch countries list from the API on component mount
        const fetchCountries = async () => {
            const GET_URL = `/v1/get-all-countries`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries', error);
            }
        };

        const fetchLanguages = async () => {
            const GET_URL = `/v1/get-all-languages`;
            try {
                const response = await api.get(GET_URL);
                if (!response.status === 200) {
                    const error = new Error(response.error);
                    throw error;
                }
                setLanguages(response.data);
            } catch (error) {
                console.error('Error fetching languages', error);
            }
        };

        fetchCountries();
        fetchLanguages();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'image/png') {
            setFile(selectedFile);
        } else {
            alert('Please select a PNG image.');
        }
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value.slice(0, 30));
    };

    const handleStageChange = (e) => {
        setStage(e.target.value);
    };

    const handleCountryChange = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            const prevCountries = [...selectedCountries];
            setSelectedCountries([...prevCountries, value]);
        } else {
            setSelectedCountries((prev) => prev.filter(country => country !== value))
        }
    };

    const handleLanguageChange = (e) => {
        if(e.target.value){
            setSelectedLanguage(parseInt(e.target.value,10));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName || !stage || selectedCountries.length === 0 || !selectedLanguage) {
            alert('Please fill all fields.');
            return;
        }

        const formData = new FormData();
        if (file) formData.append('image', file);
        formData.append('path', 'resource-categories');

        try {
            // const resImg = await api.post(`/v1/upload-image`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });
            // if (resImg.status !== 200) {
            //     throw new Error("Failed to save image");
            // }

            const resCat = await api.post(`/v1/resource-categories`,
                {
                    image_url: "resImg.data.image_url",
                    name: categoryName,
                    stage,
                    active: true
                });
            if (resCat.status !== 200) {
                throw new Error("Failed to save data");
            }

            const resCatCountries = await api.post(`/v1/resource-category-countries`,
                {
                    categoryId: resCat.data.id,
                    countries: JSON.stringify(selectedCountries)
                });
            if (resCatCountries.status !== 200) {
                throw new Error("Failed to save countries");
            }

            const resCatLanguage = await api.post(`/v1/resource-category-language`,
                {
                    categoryId: resCat.data.id,
                    language: selectedLanguage
                });
            if (resCatLanguage.status !== 200) {
                throw new Error("Failed to save language");
            }

            alert('Category saved successfully!');

            navigate('/dashboard/resourcescategory', {
                replace: true
            });

            setFile(null);
            setCategoryName("");
            setStage("");
            setCountries([]);
            setSelectedCountries([]);
            setLanguages([]);
            setSelectedLanguage(null);
        } catch (error) {
            console.error('Error saving category', error);
            alert('Failed to save category.');
        }
    };

    return (
        <Container fluid="true">
            <Typography variant="h4" gutterBottom>
                New Resource Category
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                        <input
                            type="file"
                            id="fileInput"
                            accept=".png"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <Button variant="outlined" component="label" htmlFor="fileInput">
                            Upload Image
                        </Button>
                        <Typography variant="caption" color="textSecondary">
                            Dimensions (60px x 60px) in PNG format
                        </Typography>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <TextField
                        label="Category Name"
                        id="categoryNameInput"
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        inputProps={{ maxLength: 30 }}
                        sx={{ m: 1, minWidth: 440 }} size="small"
                    />
                </Box>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
                        <InputLabel htmlFor="stageSelect">Stage</InputLabel>
                        <Select
                            id="stageSelect"
                            value={stage}
                            onChange={handleStageChange}
                            label="Stage"
                        >
                            <MenuItem value=""><em>Select a stage</em></MenuItem>
                            <MenuItem value="1">Pre-pregnancy</MenuItem>
                            <MenuItem value="2">Pregnancy</MenuItem>
                            <MenuItem value="3">Parenting</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <FormControl component="fieldset">
                        <Typography variant="h6" gutterBottom>
                            Countries
                        </Typography>
                        <FormGroup>
                            {countries.map(country => (
                                <FormControlLabel
                                    key={country.id}
                                    control={
                                        <Checkbox
                                            id={`countryCheckbox-${country.id}`}
                                            value={country.id}
                                            onChange={handleCountryChange}
                                        />
                                    }
                                    label={country.name}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box mb={2}>
                    <FormControl sx={{ m: 1, minWidth: 440 }} size="small" >
                        <InputLabel htmlFor="languageSelect">Language</InputLabel>
                        <Select
                            id="languageSelect"
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            label="Language"
                        >
                            {/* <MenuItem value=""><em>Select a language</em></MenuItem> */}
                            {
                                languages && languages.length > 0 ?
                                    (
                                        languages.map(country => (
                                            <MenuItem
                                                value={country.id}
                                            ><em>{country.name}</em></MenuItem>
                                        ))
                                    ) :
                                    (
                                        <Container/>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
            </form>
        </Container>
    );
    /*
    const handleInputs = (e) => {
        setNewResourceCategory({ ...newResourceCategory, [e.target.name]: e.target.value })
    }

    // post image api
    const formData = new FormData();
    formData.append('image', file);
    formData.append('path', 'resource-categories');

    const POST_IMAGE = `/v1/upload-image`;
    const POST_URL = `/v1/resource-categories`;
    const postCommunityCategory = async () => {
        try {

            const response1 = await api.post(POST_IMAGE, formData,
            );
            if (response1.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            if (response1.status === 200) {
                const response2 = await api.post(POST_URL,
                    {
                        image_url: response1.data.image_url,
                        name: newResourceCategory.name,
                        stage: newResourceCategory.stage,
                        active: true
                    },
                )
                if (response2.status !== 200) {
                    throw new Error("Failed to fetch data");
                }
            }
            navigate('/dashboard/resourcescategory', { replace: true })
            setNewResourceCategory({ name: "", stage: "" })
            setFile("")
        } catch (err) {
            // console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        postCommunityCategory();
    }

    const inputMissing = () => !(newResourceCategory.name && newResourceCategory.stage);
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1} mb={5}>
                    <Typography variant="h4" gutterBottom>
                        New Resource Category
                    </Typography>
                </Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            // label="Thumbnail"
                            // helperText='Thumbnail for the new Resources category'
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => setFile(e.target.files[0])} />
                            
                    </div>
                    <div >
                        <TextField
                            label="Category Name"
                            name="name"
                            id="name"
                            onChange={handleInputs}
                            value={newResourceCategory.name || ""}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 440 }} size="small">
                            <InputLabel id="demo-select-small">Stage</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                label="Stage"
                                name='stage'
                                sx={{ p: 1 }}
                                value={newResourceCategory.stage || ""}
                                onChange={handleInputs}
                            >
                                <MenuItem value={1}>Pre-pregnancy</MenuItem>
                                <MenuItem value={2}>Pregnancy</MenuItem>
                                <MenuItem value={3}>Parenting</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button variant='contained' style={{ marginLeft: "10px" }}
                        disabled={inputMissing()}
                        onClick={handleSubmit} >Submit</Button>
                </Box>
            </Container>
        </>
    )
        */
}

export default NewResourcesCategory;